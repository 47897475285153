async function checkCBK(study, isRunningCheckCBK, data) {
  const update_array = [];
  const steop1 = study.subjectStatusEntry.find((i) => i.subject_id == "1");
  const steop2 = study.subjectStatusEntry.find((i) => i.subject_id == "2");
  if (steop1.status == "done" && steop2.status == "done") {
    data.isRunningCheckCBK = true;
    study.subjectStatusEntry.forEach((item) => {
      if (
        item.subject_id !== 5 &&
        item.subject_id !== 6 &&
        item.subject_id !== 20 &&
        item.subject_id !== 21 &&
        item.status == "unavailable"
      ) {
        update_array.push({
          study_id: study.study_id,
          subject_id: item.subject_id,
          status: "can-do",
          grade: 0,
        });
      }
    });
  } else {
    study.subjectStatusEntry.forEach((item) => {
      if (parseInt(item.subject_id, 10) > 4) {
        update_array.push({
          study_id: study.study_id,
          subject_id: item.subject_id,
          status: "unavailable",
          grade: 0,
        });
      }
    });
    data.isRunningCheckCBK = false;
  }
  return update_array;
}


async function checkBusinessAnalytics(study, isRunningCheckCBK) {
  const update_array = [];
  const quanMeth2 = study.subjectStatusEntry.find((i) => i.subject_id == "4");
  const busAn1 = study.subjectStatusEntry.find((i) => i.subject_id == "5");
  const busAn2 = study.subjectStatusEntry.find((i) => i.subject_id == "6");
  if (
    quanMeth2.status == "done" &&
    (busAn1.status == "unavailable" || busAn2.status == "unavailable") &&
    isRunningCheckCBK
  ) {
    busAn1.status = "can-do";
    busAn2.status = "can-do";
    update_array.push(
      {
        study_id: study.study_id,
        subject_id: busAn1.subject_id,
        status: busAn1.status,
        grade: 0,
      },
      {
        study_id: study.study_id,
        subject_id: busAn2.subject_id,
        status: busAn2.status,
        grade: 0,
      }
    );
  } else if (quanMeth2.status !== "done") {
    busAn1.status = "unavailable";
    busAn2.status = "unavailable";
    update_array.push(
      {
        study_id: study.study_id,
        subject_id: busAn1.subject_id,
        status: busAn1.status,
        grade: 0,
      },
      {
        study_id: study.study_id,
        subject_id: busAn2.subject_id,
        status: busAn2.status,
        grade: 0,
      }
    );
  }
  return update_array;
}
async function checkSbwl(study, ectsCount, data) {
  const update_array = [];
  const sbwl1 = study.subjectStatusEntry.find((i) => i.subject_id == "20");
  if (
    data.ectsCount >= 54 &&
    (sbwl1.status == "unavailable")
  ) {
    sbwl1.status = "can-do";
    update_array.push(
      {
        study_id: study.study_id,
        subject_id: sbwl1.subject_id,
        status: sbwl1.status,
        grade: 0,
      }
    );
  } else if (data.ectsCount < 54) {
    sbwl1.status = "unavailable";
    update_array.push(
      {
        study_id: study.study_id,
        subject_id: sbwl1.subject_id,
        status: sbwl1.status,
        grade: 0,
      }
    );
  }
  return update_array;
}
async function checkBachelorarbeit(study) {
  const update_array = [];
  const academicSkills1 = study.subjectStatusEntry.find(
    (i) => i.subject_id == "17"
  );
  const academicSkills2 = study.subjectStatusEntry.find(
    (i) => i.subject_id == "18"
  );
  const bachelorArb = study.subjectStatusEntry.find(
    (i) => i.subject_id == "21"
  );
  if (
    academicSkills1.status == "done" &&
    academicSkills2.status == "done" &&
    bachelorArb.status == "unavailable"
  ) {
    bachelorArb.status = "can-do";
    update_array.push({
      study_id: study.study_id,
      subject_id: bachelorArb.subject_id,
      status: bachelorArb.status,
      grade: 0,
    });
  } else if (
    academicSkills1.status !== "done" ||
    academicSkills2.status !== "done"
  ) {
    bachelorArb.status = "unavailable";
    update_array.push({
      study_id: study.study_id,
      subject_id: bachelorArb.subject_id,
      status: bachelorArb.status,
      grade: 0,
    });
  }
  return update_array;
}

export default {
  /** @TIKO @TODO Documentation
   * Checks the study plan for the WISO IBW Bachelor
   * @param {*} study The study object
   * @param {*} data
   * @param {*} isRunningCheckCBK
   * @param {*} twoLvCheck
   * @param {*} ectsCount
   * @returns {Array} update_array An array of subject updates that have to be pushed to the backend
   */
  async executeAll(study, data, isRunningCheckCBK, twoLvCheck, ectsCount) {
    const update_array = [
      /**
       * {
       *   study_id: string,
       *   subject_id: number,
       *   status: string,
       *   grade: number
       * }
       * */
    ];

    const cbkValues = await checkCBK(study, isRunningCheckCBK, data);
    const businessAnalyticsValues = await checkBusinessAnalytics(
      study,
      isRunningCheckCBK
    );
    const sbwlValues = await checkSbwl(study, ectsCount, data);
    const bachelorarbeitValues = await checkBachelorarbeit(study);

    update_array.push({ cbkValues });
    update_array.push({ businessAnalyticsValues });
    update_array.push({ sbwlValues });
    update_array.push({ bachelorarbeitValues });

    return update_array;
  },
};
