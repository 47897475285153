function showPath(subject, status) {
    if(subject._id > 3) {
        status[0].array.forEach(obj => {
          obj.active = true
        })
        if (subject._id >= 15 && subject._id <= 21) {
            status[0].array.forEach(obj => {
              obj.active = true
            })
            const glzr = status[1].array.find(
                (s) => s._id == 13
              )
              glzr.active = true
            if (subject._id == 18) {
              const zff = status[2].array.find(
                (s) => s._id == 17
              )
              zff.active = true
            }
            else if (subject._id == 19) {
              const zff = status[2].array.find(
                (s) => s._id == 17
              )
              const fpsPrivatrecht = status[2].array.find(
                (s) => s._id == 18
              )
              zff.active = true;
              fpsPrivatrecht.active = true
            }
        }
            else if (subject._id == 22 || subject._id == 23) {
              const glzr = status[1].array.find(
                (s) => s._id == 13
              )
              const öffRecht = status[1].array.find(
                (s) => s._id == 12
              )
              glzr.active = true;
              öffRecht.active = true;
            }
            else if (subject._id >= 24 && subject._id <= 33) {
                const öffRecht = status[1].array.find(
                    (s) => s._id == 12
                  )
                  öffRecht.active = true;

                  if(subject._id == 27) {
                    const kurs1 = status[2].array.find(
                        (s) => s._id == 24
                      )
                      const kurs2 = status[2].array.find(
                        (s) => s._id == 25
                      )
                      const kurs3 = status[2].array.find(
                        (s) => s._id == 26
                      )
                      kurs1.active = true;
                      kurs2.active = true;
                      kurs3.active = true
                  } else if (subject._id == 28) {
                    const kurs1 = status[2].array.find(
                        (s) => s._id == 24
                      )
                      const kurs2 = status[2].array.find(
                        (s) => s._id == 25
                      )
                      const kurs3 = status[2].array.find(
                        (s) => s._id == 26
                      )
                      const kurs4 = status[2].array.find(
                        (s) => s._id == 27
                      )
                      kurs1.active = true;
                      kurs2.active = true;
                      kurs3.active = true;
                      kurs4.active = true
                  }
            } else if (subject._id == 34) {
                status[1].array.forEach(obj => {
                    if(obj._id >= 5 && obj._id <= 10)
                    obj.active = true
                  })
                  const statistik = status[1].array.find(
                    (s) => s._id == 14
                  )
                  statistik.active = true
            } else if  (subject._id == 35) {
                status[1].array.forEach(obj => {
                    obj.active = true
                  })
                  const gwa = status[2].array.find(
                    (s) => s._id == 22
                  )
                  gwa.active = true
            }
                
      }
}

  export default {
    executePath(subject, status) {
        showPath(subject, status)
    }
  }