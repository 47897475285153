<template>
  <div>
    <q-btn
      @click="card = true"
      label="Feedback"
      color="amber-7"
      icon-right="chat"
      outline
      style="font-weight: 600"
      size="sm"
    ></q-btn>
    <q-dialog position="bottom" v-model="card" @hide="resetValue">
      <q-card>
        <q-card-section>
          <div class="text-h5 text-bold text-amber-7 text-center">Feedback</div>
        </q-card-section>
        <q-card-section>
          <div class="text-body1">
            {{$t('feedback.thank_text_first_part')}}
          </div>
           <div class="text-body1">
            {{$t('feedback.thank_text_second_part')}}
            </div>
        </q-card-section>
        <q-card-section>
          <div class="text-body1">
            <div class="text-bold text-amber-7"> {{$t('feedback.section')}} </div>
            <q-radio v-model="feature" color="amber-7" val="COURSES" label="My Study" />
            <q-radio v-model="feature" color="amber-7" val="PLANNER" label="LV-Planer" />
            <q-radio v-model="feature" color="amber-7" val="PROFCHECK" label="Profcheck" />
            <q-radio v-model="feature" color="amber-7" val="GENERAL" label="Generell" />
          </div>
        </q-card-section>
        <q-card-section>
            <div class="text-body1">
              <div class="text-bold text-amber-7">{{$t('feedback.category')}}</div>
              <q-radio v-model="category" color="amber-7" val="BUG" label="Bug" />
              <q-radio v-model="category" color="amber-7" val="FEATURE" label="Feature" />
              <q-radio v-model="category" color="amber-7" val="DESIGN" label="Design" />
              <q-radio v-model="category" color="amber-7" val="SUGGESTION" label="Empfehlung" />
              <q-radio v-model="category" color="amber-7" val="TYPO" label="Textinhalt" />
              <q-radio v-model="category" color="amber-7" val="OTHER" label="Sonstiges" />
            </div>
        </q-card-section>
        <q-card-section>
          <q-input
            v-model="emailText"
            filled
            type="textarea"
            :label="$t('feedback.description')"
          />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn flat color="negative" @click="card = false">{{$t('common.close_button')}}</q-btn>
          <q-btn flat color="positive" @click="sendFeedback"> {{$t('common.send_button')}} </q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { useUserStore } from "@/store/user.store";
import { useQuasar } from "quasar";
import { ref } from "vue";
export default {
  setup() {
    const userStore = useUserStore();
    const q = useQuasar();
    const category = ref("");
    const feature = ref("");
    return {
      card: ref(false),
      userStore,
      q,
      category,
      feature
    };
  },
  data() {
    return {
      emailText: ref(""),
    };
  },
  methods: {
    async sendFeedback() {
      await this.userStore.sendFeedback(
        this.category,
        this.feature,
        this.emailText,
        this.q.notify
      );
      this.emailText = "";
      this.category = "";
      this.feature = "";
    },
    resetValue() {
      this.emailText = "";
      this.category = "";
      this.feature = "";
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Poppins&display=swap');

button {
  font-family: "Poppins", sans-serif;
}
</style>
