
import './styles/quasar.sass'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import Notify from 'quasar/src/plugins/notify/Notify.js';import Dialog from 'quasar/src/plugins/dialog/Dialog.js';

// To be used on app.use(Quasar, { ... })
export default {
  config: {},
  plugins: {
    Notify,
    Dialog
  }
}