function showPath(subject, status) {
    if(subject._id > 4 && subject._id < 20) {
        status[0].array.forEach(obj => {
          obj.active = true
        })
        if (subject._id == 6) {
          const amc1 = status[1].array.find(
            (s) => s._id == 5
          )
          amc1.active = true
        } else if (subject._id == 14) {
            const wiko1 = status[2].array.find(
                (s) => s._id == 15
              )
              const amc1 = status[1].array.find(
                (s) => s._id == 5
              )
              const amc2 = status[1].array.find(
                (s) => s._id == 6
              )
              const wpr = status[1].array.find(
                (s) => s._id == 10
              )
              wiko1.active = true
              amc1.active = true
              amc2.active = true
              wpr.active = true
        }
      }
      else if (subject._id >= 20) {
        status[0].array.forEach(obj => {
          obj.active = true
        })
        const amc1 = status[1].array.find(
            (s) => s._id == 5
          )
          const amc2 = status[1].array.find(
            (s) => s._id == 6
          )
          const wpr = status[1].array.find(
            (s) => s._id == 10
          )
          amc1.active = true
          amc2.active = true
          wpr.active = true

        if (subject._id == 30 || subject._id == 31 ) {
          const bis1 = status[1].array.find(
            (s) => s._id == 7
          )
          const statistik = status[1].array.find(
            (s) => s._id == 11
          )
          bis1.active = true;
          statistik.active = true
        }
        else if (subject._id == 32) {
            const bis1 = status[1].array.find(
                (s) => s._id == 7
              )
              const statistik = status[1].array.find(
                (s) => s._id == 11
              )
              bis1.active = true;
              statistik.active = true
        }
        else if (subject._id == 34) {
            const bis1 = status[1].array.find(
                (s) => s._id == 7
              )
              const statistik = status[1].array.find(
                (s) => s._id == 11
              )
              const gwa = status[2].array.find(
                (s) => s._id == 28
              )
              const forschungsmethoden = status[2].array.find(
                (s) => s._id == 29
              )
              bis1.active = true;
              statistik.active = true
              gwa.active = true;
              forschungsmethoden.active = true
        }
      }
}

  export default {
    executePath(subject, status) {
        showPath(subject, status)
    }
  }