import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import { useUserStore } from "../store/user.store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/feedback",
    name: "Feedback",
    component: () => import("../views/Feedback.vue"),
  },
  {
    path: "/mystudy",
    name: "My Study",
    component: () => import("../views/MyStudy.vue"),
  },
  {
    path: "/studies",
    name: "Studies",
    component: () => import("../views/Studies.vue"),
  },
  {
    path: "/professors",
    name: "Professors",
    component: () => import("../views/Professors.vue"),
  },
  {
    path: "/professor",
    name: "Professor",
    component: () => import("../views/Professor.vue"),
  },
  {
    path: "/professor/rate",
    name: "Professor Rating",
    component: () => import("../views/RateProfessor.vue"),
  },
  {
    path: "/professor/suggest",
    name: "Suggest Professor",
    component: () => import("../views/SuggestProfessor.vue"),
  },
  {
    path: "/login/success",
    name: "Login Success",
    component: () => import("../views/auth/LoginSuccess.vue"),
  },
  {
    path: "/test-plan",
    component: () => import("../views/plans/TestView.vue"),
  },
  {
    path: "/test-plan2",
    component: () => import("../views/plans/TestView2.vue"),
  },
  {
    path: "/lvplaner",
    name: "LV Planer",
    component: () => import("../views/LvPlaner.vue"),
  },
  {
    path: "/addlv",
    name: "Add LV",
    component: () => import("../views/AddLv.vue"),
  },
  {
    path: "/plan/:id",
    component: () => import("../views/plans/StudyPlan.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("../views/NotFoundView.vue"),
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { left: 0, top: 0 };
  }
});

// Middleware to check the users login status before every route change

router.beforeEach = async (to) => {
  const userStore = useUserStore();

  if (userStore.loggedIn) {
    try {
      await userStore.fetchUser();
    } catch (e) {
      console.error(e);
      userStore.clearAuthState();
    }
  }

  if (to.name === "My Study" && !userStore.loggedIn) {
    return { name: "Home" };
  }
};

export default router;
