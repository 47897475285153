<template>
  <div>
    <q-layout view="lHh lpr lFf" style="max-height: 150px; min-height: 100px">
      <q-header elevated class="bg-white" v-if="this.$q.platform.is.desktop">
        <div class="row">
          <q-toolbar>
            <!-- Can't use router-link because then the styling is off -->
            <div class="col-3 row items-center" style="max-height: 80px;">
              <q-avatar :size="logoSize">
                <img
                  src="../assets/oeh_logo.svg"
                  loading="lazy"
                  style="cursor: pointer"
                  @click="$router.push('/')"
                />
              </q-avatar>
              <div
                class="text-h6 q-px-xs rounded-borders"
                style="background-color: #fad785; color: white; cursor: pointer"
                @click="$router.push('/')"
              >
                BETA
              </div>
              <!-- Feedback Button -->
              <div class="q-pl-xl">
                <Feedback />
              </div>
            </div>
            <div class="col-6 row">
              <div class="col-12 row justify-between">
                <!-- <div class="div-block-3">
                <button @click="createMe" class="button w-button">CREATE /me</button>
            </div> -->
                <div v-if="store.loggedIn" class="row justify-center items-center">
                  <router-link
                    to="/mystudy"
                    class="button w-button"
                  >
                    {{ $t("navbar.mystudy") }}
                  </router-link>
                </div>
                <div class="row justify-center items-center">
                  <router-link to="/studies" class="button-2 w-button">
                    {{ $t("navbar.studies") }}
                  </router-link>
                </div>
                <div class="row justify-center items-center">
                  <router-link to="/lvplaner" class="button-2 w-button"
                    >LV-Planer</router-link
                  >
                </div>
                <div class="row justify-center items-center">
                  <router-link to="/professors" class="button-2 w-button"
                    >Profcheck</router-link
                  >
                </div>
                </div>
            </div>
            <div class="col-3 row items-center">
                  <div class="col-12 row justify-end">
                    <div class="col-9 row justify-end">
                  <!-- Conditional rendering dependent on logged in state -->
                  <div class="row items-center button" v-if="store.loggedIn">
                    <button @click="logout" class="button">
                      {{ store.getStudentId }}
                    </button>
                    <q-icon
                      @click="logout"
                      name="logout"
                      size="sm"
                      color="amber-5"
                    />
                  </div>
                  <div class="row items-center button" v-else @click="login">
                    <button class="button w-button">LogIn</button>
                    <q-icon
                      @click="logout"
                      name="person"
                      size="sm"
                      color="amber-5"
                    />
                  </div>
                  </div>
                  <div class="col-3 row justify-end">
                  <q-btn-dropdown :label="this.$i18n.locale" class="button" size="md" padding="sm" >
                    <q-list>
                      <q-item clickable v-close-popup @click="changeLocale('en')">
                        <q-item-section>
                          <q-item-label>English</q-item-label>
                        </q-item-section>
                      </q-item>

                      <q-item clickable v-close-popup @click="changeLocale('de')">
                        <q-item-section>
                          <q-item-label>Deutsch</q-item-label>
                        </q-item-section>
                      </q-item>
                    </q-list>
                  </q-btn-dropdown>
                  </div>
                  </div>
            </div>
          </q-toolbar>
        </div>
      </q-header>
      <q-header
        elevated
        class="bg-white text-blue-4"
        v-if="this.$q.platform.is.mobile"
      >
        <q-toolbar>
          <q-avatar :size="logoSize">
            <img
              src="../assets/oeh_logo.svg"
              loading="lazy"
              style="cursor: pointer"
              @click="$router.push('/')"
            />
          </q-avatar>
          <div
            class="text-caption q-px-xs rounded-borders"
            style="background-color: #fad785; color: white"
          >
            BETA
          </div>
          <!-- Feedback button -->
          <div class="q-pl-md">
            <Feedback />
          </div>

          <q-toolbar-title></q-toolbar-title>

          <q-btn flat round dense icon="menu" class="q-mr-sm">
            <q-menu>
              <q-list style="min-width: 100px">
                <q-item clickable v-close-popup v-if="store.loggedIn">
                  <q-item-section
                    ><router-link class="button-mobile" to="/mystudy">
                      {{ $t("navbar.mystudy") }}
                    </router-link></q-item-section
                  >
                </q-item>
                <q-separator />
                <q-item clickable v-close-popup>
                  <q-item-section>
                    <router-link to="/studies" class="button-mobile">
                      {{ $t("navbar.studies") }}
                    </router-link>
                  </q-item-section>
                </q-item>
                <q-separator />
                <q-item clickable v-close-popup>
                  <q-item-section>
                    <router-link to="/lvplaner" class="button-mobile"
                      >LV-Planer</router-link
                    >
                  </q-item-section>
                </q-item>
                <q-separator />
                <q-item clickable v-close-popup>
                  <q-item-section
                    ><router-link to="/professors" class="button-mobile"
                      >Profcheck</router-link
                    ></q-item-section
                  >
                </q-item>
                <q-separator />
                <q-item clickable v-close-popup class="button-mobile">
                  <q-item-section v-if="store.loggedIn" @click="logout">
                    {{ store.getStudentId }}
                    <q-icon
                      @click="logout"
                      name="logout"
                      size="xs"
                      color="amber-5"
                    />
                  </q-item-section>
                  <q-item-section v-else @click="login">
                    LogIn
                    <q-icon
                      @click="logout"
                      name="person"
                      size="xs"
                      color="amber-5"
                    />
                  </q-item-section>
                </q-item>
                <q-separator />
                <q-item clickable>
                  <q-item-section>
                    <q-btn-dropdown :label="this.$i18n.locale" class="button" size="md" dense flat >
                      <q-list>
                        <q-item clickable v-close-popup @click="changeLocale('en')">
                          <q-item-section>
                            <q-item-label>English</q-item-label>
                          </q-item-section>
                        </q-item>

                        <q-item clickable v-close-popup @click="changeLocale('de')">
                          <q-item-section>
                            <q-item-label>Deutsch</q-item-label>
                          </q-item-section>
                        </q-item>
                      </q-list>
                    </q-btn-dropdown>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-btn>
        </q-toolbar>
      </q-header>
    </q-layout>
  </div>
</template>

<script>
import { useUserStore } from "@/store/user.store";
import { ref } from "vue";
import { QSpinnerGears } from "quasar";
import { getOAuthUrl } from "@/store/config";
import Feedback from "../views/Feedback.vue";

export default {
  components: {
    Feedback,
  },
  setup() {
    const userStore = useUserStore();

    return {
      store: ref(userStore),
    };
  },
  computed: {
    logoSize() {
      return this.$q.platform.is.iphone ? "50px" : "80px";
    },
    toolButtons() {
      return this.$q.platform.is.desktop ? "col-8" : "col-8";
    },
  },
  methods: {
    login() {
      window.location.href = getOAuthUrl();
    },
    async logout() {
      const notif = this.$q.notify({
        color: "primary",
        textColor: "white",
        message: "Logging out...",
        spinner: QSpinnerGears,
        timeout: 0,
        group: false,
      });

      await this.store.logout(notif);
    },
    changeLocale(locale) {
      this.$i18n.locale = locale;
      sessionStorage.setItem('selectedLocale', locale);
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Lato&family=Poppins&display=swap");

.button {
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  color: #5bbdf4;
  font-weight: bold;
  font-size: 25px;
  text-decoration: none;
  border: none;
  cursor: pointer;
  transition: 100ms ease-in;
}

.button-2 {
  background-color: transparent;
  font-family: "Poppins", sans-serif;
  color: #5bbdf4;
  font-weight: bold;
  font-size: 1.8em;
  text-decoration-line: none;
  border: none;
  cursor: pointer;
  transition: 100ms ease-in;
}

.button-mobile {
  text-decoration: none;
  color: #5bbdf4;
}

.button:hover,
.button-2:hover {
  color: #acdbf5;
}

/* MEDIA QUERIES */

/*X-Small devices (landscape phones, 576px and up)*/
@media (min-width: 375px) and (max-width: 767.98px) {
  .button {
    font-size: 0.6rem;
  }

  .button-2 {
    font-size: 0.6rem;
  }
}

/* Medium devices (tablets, 768px and up)*/
@media (min-width: 767.99px) and (max-width: 1199.98px) {
  .button {
    font-size: 1.4rem;
  }

  .button-2 {
    font-size: 1.4rem;
  }
}

/*Large devices (desktops, 992px and up) */
@media (min-width: 1199.99px) and (max-width: 1399.98px) {
  .button {
    font-size: 1rem;
  }

  .button-2 {
    font-size: 1rem;
  }
}
</style>