function showPath(subject, status) {
  if (subject._id >= 4 && subject._id <= 14) {
    status[0].array.forEach((obj) => {
      obj.active = true;
    });
  } else if (subject._id > 14) {
    status[0].array.forEach((obj) => {
      obj.active = true;
    });
    if (subject._id == 28) {
      const kurs1 = status[1].array.find((s) => s._id == 4);
      const kurs2 = status[1].array.find((s) => s._id == 12);
      const kurs3 = status[1].array.find((s) => s._id == 13);
      kurs1.active = true;
      kurs2.active = true;
      kurs3.active = true;
    } else if (subject._id == 24) {
      const kurs1 = status[2].array.find((s) => s._id == 23);

      kurs1.active = true;
    } else if (subject._id == 18) {
      const kurs1 = status[1].array.find((s) => s._id == 4);
      kurs1.active = true;
    } else if (subject._id == 31) {
      const kurs1 = status[1].array.find((s) => s._id == 4);
      const kurs2 = status[1].array.find((s) => s._id == 12);
      const kurs3 = status[1].array.find((s) => s._id == 13);
      const kurs4 = status[1].array.find((s) => s._id == 14);
      kurs1.active = true;
      kurs2.active = true;
      kurs3.active = true;
      kurs4.active = true;
    }
  }
}

export default {
  executePath(subject, status) {
    showPath(subject, status);
  },
};
