
  async function checkCBK(study, isRunningCheckCBK, data) {
    const update_array = [];
    const steop1 = study.subjectStatusEntry.find((i) => i.subject_id == "1");
    const steop2 = study.subjectStatusEntry.find((i) => i.subject_id == "2");
    const steop3 = study.subjectStatusEntry.find((i) => i.subject_id == "3");
    if ([steop1, steop2, steop3].every(item => item.status == 'done')) {
      data.isRunningCheckCBK = true;

      study.subjectStatusEntry.forEach(item => {
        if (item.subject_id >= 4 &&
            item.subject_id <= 14 &&
            item.status == "unavailable") {
              update_array.push({
                study_id: study.study_id,
                subject_id: item.subject_id,
                status: "can-do",
                grade: 0,
              });
        }
      });
    } else {
      study.subjectStatusEntry.forEach(item => {
        if (parseInt(item.subject_id, 10) > 3 && item.subject_id !== 37) {
          item.status = "unavailable";
          update_array.push({
            study_id: study.study_id,
            subject_id: item.subject_id,
            status: "unavailable",
            grade: 0,
          });
          data.isRunningCheckCBK = false;
        }
      })
    }
    return update_array;
  }
  async function checkWahlfach(study) {
    const update_array = [];
    const steop1 = study.subjectStatusEntry.find((i) => i.subject_id == "1");
    const steop2 = study.subjectStatusEntry.find((i) => i.subject_id == "2");
    const steop3 = study.subjectStatusEntry.find((i) => i.subject_id == "3");
    const wahlfach = study.subjectStatusEntry.find((i) => i.subject_id == "37");

    if ([steop1, steop2, steop3].some(item => item.status == 'done') && wahlfach.status == 'unavailable')  {
      wahlfach.status = 'can-do'
      update_array.push({
        study_id: study.study_id,
        subject_id: wahlfach.subject_id,
        status: wahlfach.status,
        grade: 0,
      });
    } else if ([steop1, steop2, steop3].every(item => item.status == 'can-do')) {
      wahlfach.status = 'unavailable'
      update_array.push({
        study_id: study.study_id,
        subject_id: wahlfach.subject_id,
        status: wahlfach.status,
        grade: 0,
      });
    }
    return update_array;
  }
  async function checkHauptstudium(study, twoLvCheck, ectsCount, data) {
    const update_array = [];
    const hauptstudium = study.subjectStatusEntry.slice(14, 39);
    const hauptstudiumFiltered = hauptstudium.filter(item => !["27","28","29","30","31","32","33","34","35","36","37","38"].includes(item.subject_id))
    if (data.ectsCount >= 36) {
      data.twoLvCheck = true;
      hauptstudiumFiltered.forEach(item => {
        if (item.status == 'unavailable') {
          item.status = 'can-do'
          update_array.push({
            study_id: study.study_id,
            subject_id: item.subject_id,
            status: item.status,
            grade: 0,
          });
        }
      })
    } else {
      data.twoLvCheck = false;
      hauptstudiumFiltered.forEach(item => {
        item.status = 'unavailable'
        update_array.push({
          study_id: study.study_id,
          subject_id: item.subject_id,
          status: item.status,
          grade: 0,
        });
      })
    }
    return update_array;
  }
  async function checkSpezangebot(study, twoLvCheck) {
    const update_array = [];
    const spezialisierungsangebot = study.subjectStatusEntry.slice(26, 36)
    const doneItems = spezialisierungsangebot.filter(item => item.status == 'done' || item.status == 'doing');
    if (twoLvCheck) {
      if (doneItems.length >= 3) {
        spezialisierungsangebot.forEach(item => {
            if (item.status == 'can-do') {
          item.status = 'unavailable'
          update_array.push({
            study_id: study.study_id,
            subject_id: item.subject_id,
            status: item.status,
            grade: 0,
          });
            }
        })
      } else {
        spezialisierungsangebot.forEach(item => {
            if (item.status == 'unavailable') {
          item.status = 'can-do'
          update_array.push({
            study_id: study.study_id,
            subject_id: item.subject_id,
            status: item.status,
            grade: 0,
          });
            }
        })
      }
    }
    else {
      spezialisierungsangebot.forEach(item => {
            item.status = 'unavailable'
            update_array.push({
              study_id: study.study_id,
              subject_id: item.subject_id,
              status: item.status,
              grade: 0,
            });
        })
    }
  
    return update_array;
  }
  async function checkSbwl(study, twoLvCheck) {
    const update_array = [];
    const sbwl1 = study.subjectStatusEntry.find((i) => i.subject_id == "36");
    const amc1 = study.subjectStatusEntry.find((i) => i.subject_id == "4");
    const mathe = study.subjectStatusEntry.find((i) => i.subject_id == "12");
    const statistik = study.subjectStatusEntry.find((i) => i.subject_id == "13");
    if (twoLvCheck) {
      if (([amc1, mathe, statistik].every(item => item.status == 'done'))) {
        if (sbwl1.status == 'unavailable') {
          sbwl1.status = 'can-do'
          update_array.push({
            study_id: study.study_id,
            subject_id: sbwl1.subject_id,
            status: sbwl1.status,
            grade: 0,
          });
        } 
    } else if (!([amc1, mathe, statistik].every(item => item.status == 'done'))) {
      sbwl1.status = 'unavailable';
      update_array.push({
        study_id: study.study_id,
        subject_id: sbwl1.subject_id,
        status: sbwl1.status,
        grade: 0,
      });
    }
  } else {
      sbwl1.status = 'unavailable';
      update_array.push({
        study_id: study.study_id,
        subject_id: sbwl1.subject_id,
        status: sbwl1.status,
        grade: 0,
      });
  }
  return update_array;
  }
  async function checkBachelorarbeit(study, twoLvCheck) {
    const update_array = [];
    const bachelorarbeit = study.subjectStatusEntry.find(
      (i) => i.subject_id == "38"
    );
    const gwa = study.subjectStatusEntry.find((i) => i.subject_id == "14");
    const amc1 = study.subjectStatusEntry.find((i) => i.subject_id == "4");
    const mathe = study.subjectStatusEntry.find((i) => i.subject_id == "12");
    const statistik = study.subjectStatusEntry.find((i) => i.subject_id == "13");
    if (twoLvCheck) {
      if ([amc1, gwa, mathe, statistik].every((item) => item.status == "done")) {
        if (bachelorarbeit.status == "unavailable") {
          bachelorarbeit.status = "can-do";
          update_array.push({
            study_id: study.study_id,
            subject_id: bachelorarbeit.subject_id,
            status: bachelorarbeit.status,
            grade: 0,
          });
        }
      } else if (
        ![amc1, gwa, mathe, statistik].every((item) => item.status == "done")
      ) {
        bachelorarbeit.status = "unavailable";
        update_array.push({
          study_id: study.study_id,
          subject_id: bachelorarbeit.subject_id,
          status: bachelorarbeit.status,
          grade: 0,
        });
      }
    } else {
      bachelorarbeit.status = "unavailable";
      update_array.push({
        study_id: study.study_id,
        subject_id: bachelorarbeit.subject_id,
        status: bachelorarbeit.status,
        grade: 0,
      });
    }
    return update_array;
  }
  export default {
    /** @TIKO @TODO Documentation
  * Checks the study plan for the WISO IBW Bachelor
  * @param {*} study The study object
  * @param {*} data 
  * @param {*} isRunningCheckCBK 
  * @param {*} twoLvCheck 
  * @param {*} ectsCount 
  * @returns {Array} update_array An array of subject updates that have to be pushed to the backend
  */
 async executeAll(study, data, isRunningCheckCBK, twoLvCheck, ectsCount) {
   const update_array = [
     /**
      * { 
      *   study_id: string,
      *   subject_id: number,
      *   status: string,
      *   grade: number
      * }
      * */
   ];

   const cbkValues = await checkCBK(study, isRunningCheckCBK, data);
    const wahlfachValues = await checkWahlfach(study);
    const hauptstudiumValues = await checkHauptstudium(study, twoLvCheck, ectsCount, data);
    const spezangebotValues = await checkSpezangebot(study, twoLvCheck);
    const sbwlValues = await checkSbwl(study, twoLvCheck);
    const bachelorarbeitValues = await checkBachelorarbeit(study, twoLvCheck);

    update_array.push({cbkValues})
    update_array.push({wahlfachValues})
    update_array.push({hauptstudiumValues})
    update_array.push({spezangebotValues})
    update_array.push({sbwlValues})
    update_array.push({bachelorarbeitValues})

    return update_array;
    }
  }