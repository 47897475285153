async function checkCBK(study, isRunningCheckCBK, data) {
    const update_array = [];
    const steop1 = study.subjectStatusEntry.find(
      (i) => i.subject_id == "1"
    );
    const steop2 = study.subjectStatusEntry.find(
      (i) => i.subject_id == "2"
    );
    const steop3 = study.subjectStatusEntry.find(
      (i) => i.subject_id == "3"
    );
    const steop4 = study.subjectStatusEntry.find(
      (i) => i.subject_id == "4"
    );
    if (
      [steop1, steop2, steop3, steop4].every((item) => item.status == "done")
    ) {
      data.isRunningCheckCBK = true;
      study.subjectStatusEntry.forEach((item) => {
        if (
          item.subject_id >= 5 &&
          item.subject_id <= 16 &&
          item.subject_id != 6 &&
          item.status == "unavailable"
        ) {
          update_array.push({
            study_id: study.study_id,
            subject_id: item.subject_id,
            status: "can-do",
            grade: 0,
          });
        }
      });
    } else {
      study.subjectStatusEntry.forEach((item) => {
        if (parseInt(item.subject_id, 10) > 4) {
          update_array.push({
            study_id: study.study_id,
            subject_id: item.subject_id,
            status: "unavailable",
            grade: 0,
          });
        }
      });
      data.isRunningCheckCBK = false;
    }
    return update_array;
  }
async function checkAMC(study) {
  const update_array = [];
    const amc1 = study.subjectStatusEntry.find(
      (i) => i.subject_id == "5"
    );
    const amc2 = study.subjectStatusEntry.find(
      (i) => i.subject_id == "6"
    );
    if (amc1.status == "done" && amc2.status == "unavailable") {
      amc2.status = "can-do";
      update_array.push({
        study_id: study.study_id,
        subject_id: amc2.subject_id,
        status: amc2.status,
        grade: 0,
      });
    } else if (amc1.status !== "done") {
      amc2.status = "unavailable";
      update_array.push({
        study_id: study.study_id,
        subject_id: amc2.subject_id,
        status: amc2.status,
        grade: 0,
      });
    }
    return update_array
  }
 async function checkLvChoice(study, isRunningCheckCBK, twoLvCheck, data) {
    const update_array = [];
    if (data.isRunningCheckCBK) {
      let doneCount = 0;
      let doneIndices = [];

      for (let i = 6; i <= 11; i++) {
        if (study.subjectStatusEntry[i].status == "done") {
          doneCount++;
          doneIndices.push(i);
        }
      }

      if (doneCount >= 2) {
        data.twoLvCheck = true;
        for (let i = 6; i <= 11; i++) {
          if (!doneIndices.includes(i)) {
            study.subjectStatusEntry[i].status = "unavailable";
            update_array.push({
              study_id: study.study_id,
              subject_id: study.subjectStatusEntry[i].subject_id,
              status: "unavailable",
              grade: 0,
            });
          }
        }
      } else {
        data.twoLvCheck = false;
        for (let i = 6; i <= 11; i++) {
          if (study.subjectStatusEntry[i].status == "unavailable") {
            study.subjectStatusEntry[i].status = "can-do";
            update_array.push({
              study_id: study.study_id,
              subject_id: study.subjectStatusEntry[i].subject_id,
              status: "can-do",
              grade: 0,
            });
          }
        }
      }
    }
    return update_array;
  }
async function checkHauptstudium(study) {
  const update_array = [];
    const excludesIds = ["20", "21", "25", "26", "37", "38"];
    const excludeSbwl = ["37"];
      const glzr = study.subjectStatusEntry.find(
        (i) => i.subject_id == "14"
      );
      if (glzr.status == "done") {
        study.subjectStatusEntry.forEach((item) => {
          if (
            item.subject_id >= 17 &&
            !excludesIds.includes(item.subject_id) &&
            item.status == "unavailable"
          ) {
            item.status = "can-do";
            update_array.push({
              study_id: study.study_id,
              subject_id: item.subject_id,
              status: item.status,
              grade: 0,
            });
          }
        });
      } else if (glzr.status !== "done") {
        study.subjectStatusEntry.forEach((item) => {
          if (item.subject_id >= 17 && !excludeSbwl.includes(item.subject_id)) {
            item.status = "unavailable";
            update_array.push({
              study_id: study.study_id,
              subject_id: item.subject_id,
              status: item.status,
              grade: 0,
            });
          }
        });
      }
      return update_array;
  }
async function checkPrRechtSchr(study) {
  const update_array = [];
    const zff = study.subjectStatusEntry.find(
      (i) => i.subject_id == "17"
    );
    const fp = study.subjectStatusEntry.find(
      (i) => i.subject_id == "20"
    );
    if (zff.status == "done" && fp.status == "unavailable") {
      fp.status = "can-do";
      update_array.push({
        study_id: study.study_id,
        subject_id: fp.subject_id,
        status: fp.status,
        grade: 0,
      });
    } else if (zff.status !== "done") {
      fp.status = "unavailable";
      update_array.push({
        study_id: study.study_id,
        subject_id: fp.subject_id,
        status: fp.status,
        grade: 0,
      });
    }
    return update_array;
  }
 async function checkPrRechtMue(study) {
  const update_array = [];
    const fpSchr = study.subjectStatusEntry.find(
      (i) => i.subject_id == "20"
    );
    const fpMue = study.subjectStatusEntry.find(
      (i) => i.subject_id == "21"
    );
    if (fpSchr.status == "done" && fpMue.status == "unavailable") {
      fpMue.status = "can-do";
      update_array.push({
        study_id: study.study_id,
        subject_id: fpMue.subject_id,
        status: fpMue.status,
        grade: 0,
      });
    } else if (fpSchr.status !== "done") {
      fpMue.status = "unavailable";
      update_array.push({
        study_id: study.study_id,
        subject_id: fpMue.subject_id,
        status: fpMue.status,
        grade: 0,
      });
    }
    return update_array;
  }
 async function checkOeffRechtSchr(study) {
  const update_array = [];
    const verfUndVerw = study.subjectStatusEntry.find(
      (i) => i.subject_id == "22"
    );
    const verwUndRecht = study.subjectStatusEntry.find(
      (i) => i.subject_id == "23"
    );
    const öffWire = study.subjectStatusEntry.find(
      (i) => i.subject_id == "24"
    );
    const fp = study.subjectStatusEntry.find(
      (i) => i.subject_id == "25"
    );
    if (
      [verfUndVerw, verwUndRecht, öffWire].every(
        (item) => item.status == "done"
      ) &&
      fp.status == "unavailable"
      
    ) {
      fp.status = "can-do";
      update_array.push({
        study_id: study.study_id,
        subject_id: fp.subject_id,
        status: fp.status,
        grade: 0,
      });
    } else if (
      [verfUndVerw, verwUndRecht, öffWire].some(
        (item) => item.status !== "done"
      )
    ) {
      fp.status = "unavailable";
      update_array.push({
        study_id: study.study_id,
        subject_id: fp.subject_id,
        status: fp.status,
        grade: 0,
      });
    }
    return update_array;
  }
 async function checkOeffRechtMue(study) {
  const update_array = [];
    const fpSchr = study.subjectStatusEntry.find(
      (i) => i.subject_id == "25"
    );
    const fpMue = study.subjectStatusEntry.find(
      (i) => i.subject_id == "26"
    );
    if (fpSchr.status == "done" && fpMue.status == "unavailable") {
      fpMue.status = "can-do";
      update_array.push({
        study_id: study.study_id,
        subject_id: fpMue.subject_id,
        status: fpMue.status,
        grade: 0,
      });
    } else if (fpSchr.status !== "done") {
      fpMue.status = "unavailable";
      update_array.push({
        study_id: study.study_id,
        subject_id: fpMue.subject_id,
        status: fpMue.status,
        grade: 0,
      });
    }
    return update_array;
  }
  async function checkSbwl(study, twoLvCheck) {
    const update_array = [];
    const amc1 = study.subjectStatusEntry.find(
      (i) => i.subject_id == "5"
    );
    const amc2 = study.subjectStatusEntry.find(
      (i) => i.subject_id == "6"
    );
    const statistik = study.subjectStatusEntry.find(
      (i) => i.subject_id == "15"
    );
    const glzr = study.subjectStatusEntry.find(
      (i) => i.subject_id == "14"
    );
    const sbwl = study.subjectStatusEntry.find(
      (i) => i.subject_id == "37"
    );
    if (
      [amc1, amc2, glzr, statistik].every((item) => item.status == "done") &&
      twoLvCheck == true && 
      sbwl.status == "unavailable"
    ) {
      sbwl.status = "can-do";
      update_array.push({
        study_id: study.study_id,
        subject_id: sbwl.subject_id,
        status: sbwl.status,
        grade: 0,
      });
      
    } else if (
      [amc1, amc2, glzr, statistik].some((item) => item.status !== "done") || twoLvCheck == false 
    ) {
      sbwl.status = "unavailable";
      update_array.push({
        study_id: study.study_id,
        subject_id: sbwl.subject_id,
        status: sbwl.status,
        grade: 0,
      });
    } 
    return update_array;
  }
 async function checkBachelor(study, twoLvCheck) {
  const update_array = [];
    const cbkFiltered = study.subjectStatusEntry.filter((item) =>
      ["5", "6", "13", "14", "15", "16"].includes(item.subject_id)
    ); 
    const gwa = study.subjectStatusEntry.find(
      (i) => i.subject_id == "36"
    );
    const bachelor = study.subjectStatusEntry.find(
      (i) => i.subject_id == "38"
    );
    if (
      cbkFiltered.every((item) => item.status == "done") &&
      twoLvCheck &&
      gwa.status == "done" &&
      bachelor.status == "unavailable"
    ) {
      bachelor.status = "can-do";
      update_array.push({
        study_id: study.study_id,
        subject_id: bachelor.subject_id,
        status: bachelor.status,
        grade: 0,
      });
    } else if (
      cbkFiltered.some((item) => item.status !== "done") ||
      gwa.status !== "done" ||
      !twoLvCheck
    ) {
      bachelor.status = "unavailable";
      update_array.push({
        study_id: study.study_id,
        subject_id: bachelor.subject_id,
        status: bachelor.status,
        grade: 0,
      });
    }
    return update_array;
  }
export default {
      /** @TIKO @TODO Documentation
   * Checks the study plan for the WISO IBW Bachelor
   * @param {*} study The study object
   * @param {*} data 
   * @param {*} isRunningCheckCBK 
   * @param {*} twoLvCheck 
   * @returns {Array} update_array An array of subject updates that have to be pushed to the backend
   */
      async executeAll(study, data, isRunningCheckCBK, twoLvCheck) {
        const update_array = [
          /**
           * { 
           *   study_id: string,
           *   subject_id: number,
           *   status: string,
           *   grade: number
           * }
           * */
        ];

    const cbkValues = await checkCBK(study, isRunningCheckCBK, data);
    const amcValues = await checkAMC(study);
    const hauptstudiumValues = await checkHauptstudium(study);
    const lvChoiceValues = await checkLvChoice(study, isRunningCheckCBK, twoLvCheck, data);
    const prRechtSchrValues = await checkPrRechtSchr(study);
    const prRechtMueValues = await checkPrRechtMue(study);
    const oeffRechtSchrValues = await checkOeffRechtSchr(study);
    const oeffRechtMueValues = await checkOeffRechtMue(study);
    const sbwlValues = await checkSbwl(study, twoLvCheck);
    const bachelorValues = await checkBachelor(study, twoLvCheck);

    update_array.push({cbkValues});
    update_array.push({amcValues});
    update_array.push({hauptstudiumValues});
    update_array.push({lvChoiceValues});
    update_array.push({prRechtSchrValues});
    update_array.push({prRechtMueValues});
    update_array.push({oeffRechtSchrValues});
    update_array.push({oeffRechtMueValues});
    update_array.push({sbwlValues});
    update_array.push({bachelorValues});

    return update_array;

 
      }
}
