<template>
  <div>
    <Navbar />

    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

    <footer>
      <Footer />
    </footer>
  </div>
</template>


<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
  },
  mounted () {
    // Set the locale from the session storage
    const selectedLocale = sessionStorage.getItem('selectedLocale');
    if (selectedLocale) {
      this.$i18n.locale = selectedLocale;
    }
  }

}
</script>


<style>
body {
  margin-top: 8px !important;
  font-family:-apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins !important;
}
</style>