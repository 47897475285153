<template>
    <div class="content-footer row justify-between">
        <div class="col-md-4 col-12 row justify-start items-start">
            <div class="content-style q-pa-md">
                <img class="logo" src="../assets/oeh_logo.svg" alt="logo" />
                <p class="text">
                    <b>ÖH WU BeratungsZentrum</b> <br />
                    Welthandelsplatz 1 <br />
                    1020 Wien
                </p>
                <div class="contact-links">
                    <div class="link-style">
                        <img class="contact-icons" src="../assets/phone.svg" alt="call" />
                        <a href="tel: +43(1)313365400">+43 (1) 31336 5400</a>
                    </div>
                    <div class="link-style">
                        <img class="contact-icons" src="../assets/mail.svg" alt="mail" />
                        <a href="mailto: beratung@oeh-wu.at">beratung@oeh-wu.at</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-12 row justify-start items-start q-pt-xl">
            <div class="text-h5 text-blue-4 q-pa-md">
                <div class="text-bold">Quick Links</div>
                <div class="site-links">
                    <router-link to="/mystudy">My Study</router-link>
                    <router-link to="/studies">Studies</router-link>
                    <router-link to="/professors">Professors</router-link>
                    <router-link to="/lvplaner">LV-Planer</router-link>
                </div>
            </div>
        </div>
        <div class="col-md-4 col-12 row justify-start items-start q-pt-xl">
            <div class="text-h5 text-blue-4 q-pa-md">
                <div class="text-bold q-pb-md">Social Media</div>
                <div class="icons">
                    <a href="https://www.instagram.com/oeh_wu/"><img class="social-media-icons"
                            src="../assets/insta.svg" alt="Instagram" /></a>
                    <a href="https://www.facebook.com/oehwu/"><img class="social-media-icons"
                            src="../assets/facebook.svg" alt="Facebook" /></a>
                    <a href="https://www.youtube.com/channel/UC2kfEqx06KzRG5c_gJVVLGw/featured"><img
                            style="width: 2.25rem" class="social-media-icons" src="../assets/youtube.svg"
                            alt="Youtube" /></a>
                </div>
            </div>
        </div>
        <div class="col-12 credits row justify-center">
            <div class="col-md-4 col-12"></div>
            <div class="col-md-4 col-12 text-center">
            <p>
                Designed and developed by Severin Mairinger and Thomas Tikovits</p>
            </div>
            <div class="col-md-4 col-12 row justify-center">
                <a class="q-pr-xs" href="https://oeh-wu.at/impressum">Impressum</a>
                <div> | </div>
                <a class="q-pl-xs" href="https://oeh-wu.at/datenschutz">Datenschutz</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        goToMyStudy() {
            this.$router.push("/mystudy");
        },
        goToStudies() {
            this.$router.push("/studies");
        },
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.content-footer {
    width: 100%;
    background-color: #fcf5eb;
    font-family:-apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
    margin-top: 30vh;
    margin-bottom: 0%;
    margin-right: 0%;
    margin-left: 0%;
}

.contact {
    grid-row: span 1;
    grid-column: span 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.easteregg {
    position: fixed;
    display: flex;
    animation: mymove 3s;
    animation-fill-mode: forwards;
    left: 50%;
}

.easteregg img {
    width: 100px;
}

.easteregg p {
    font-family:-apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
    font-weight: 800;
    -webkit-background-clip: text;
    opacity: 0%;
    animation: fadeIn 3s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0%;
    }

    to {
        opacity: 100%;
    }
}

@keyframes mymove {
    from {
        bottom: 0%;
    }

    to {
        bottom: 20%;
    }
}

.quick-links {
    grid-row: span 1;
    grid-column: span 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.social-media {
    grid-row: span 1;
    grid-column: span 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.credits {
    grid-row: span 1;
    grid-column: span 3;
    display: flex;
    justify-content: center;
    margin-top: 5rem;
    color: #888a8b;
    margin-bottom: 1px;
    font-size: 12px;
}
.credits a {
    color: #888a8b;
}


.text {
    margin-top: 0%;
    font-size: 1.1rem;
}

.contact-links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
}

.contact-links a {
    color: #5bbdf4;
    font-size: 1rem;
}

.site-links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.1rem;
}

.site-links p {
    cursor: pointer;
}

.icons {
    display: flex;
    justify-content: space-between;
    width: 9rem;
}

.link-style {
    display: flex;
}

.link-style img {
    margin-right: 8px;
}

.social-media-icons {
    width: 2rem;
    transition: 200ms;
}
.social-media-icons:hover {
    transform: scale(1.1);
}

.contact-icons {
    width: 1rem;
}

ul {
    list-style: none;
}

li {
    padding-top: 10%;
}

.logo {
    max-width: 8rem;
    max-height: auto;
    margin-top: 2rem;
    margin-bottom: 0%;
}

a {
    text-decoration: none;
    color: black;
}

h2 {
    margin-top: 2.5rem;
    color: #5bbdf4;
}

@media screen and (max-width: 650px) {
    .contact {
        grid-row: span 1;
        grid-column: span 3;
    }

    .quick-links {
        grid-row: span 1;
        grid-column: span 3;
    }

    .social-media {
        grid-row: span 1;
        grid-column: span 3;
    }

    .content-footer {
        width: 100%;
    }
}
</style>