import { createI18n } from 'vue-i18n';

function loadLocaleMessages() {
    const locales = require.context('./i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};

    locales.keys().forEach((key) => {
        const matched = key.match(/\.\/(.+?)\/(.+?)\.json$/i);
        if (matched && matched.length > 2) {
            const locale = matched[1];
            const messageKey = matched[2];
            if (!messages[locale]) messages[locale] = {};
            messages[locale][messageKey] = locales(key);
        }
    });

    return messages;
}

const defaultBrowserLocale = navigator.language || navigator.userLanguage;

const i18n = createI18n({
    locale: defaultBrowserLocale.split('-')[0],
    fallbackLocale: 'en',
    messages: loadLocaleMessages()
});

export default i18n;
