async function checkCBK(study, isRunningCheckCBK, data) {
  const update_array = [];
    const steop1 = study.subjectStatusEntry.find(
      (i) => i.subject_id == "1"
    );
    const steop2 = study.subjectStatusEntry.find(
      (i) => i.subject_id == "2"
    );
    const steop3 = study.subjectStatusEntry.find(
      (i) => i.subject_id == "3"
    );
    if (
      [steop1, steop2, steop3].every((item) => item.status == "done")
    ) {
      data.isRunningCheckCBK = true;
      study.subjectStatusEntry.forEach((item) => {
        if (
          item.subject_id >= 4 &&
          item.subject_id <= 14 &&
          item.status == "unavailable"
        ) {
          update_array.push({
            study_id: study.study_id,
            subject_id: item.subject_id,
            status: "can-do",
            grade: 0,
          });
        }
      });
    } else {
      study.subjectStatusEntry.forEach((item) => {
        if (parseInt(item.subject_id, 10) > 3) {
          update_array.push({
            study_id: study.study_id,
            subject_id: item.subject_id,
            status: "unavailable",
            grade: 0,
          });
        }
      });
      data.isRunningCheckCBK = false;
    }
    return update_array;
  }
async function checkLvChoice(study, isRunningCheckCBK, twoLvCheck, data) {
  const update_array = [];
    if (data.isRunningCheckCBK) {
      let doneCount = 0;
      let doneIndices = [];

      for (let i = 4; i <= 9; i++) {
        if (study.subjectStatusEntry[i].status == "done") {
          doneCount++;
          doneIndices.push(i);
        }
      }

      if (doneCount >= 2) {
        data.twoLvCheck = true;
        for (let i = 4; i <= 9; i++) {
          if (!doneIndices.includes(i)) {
            study.subjectStatusEntry[i].status = "unavailable";
            update_array.push({
              study_id: study.study_id,
              subject_id: study.subjectStatusEntry[i].subject_id,
              status: "unavailable",
              grade: 0,
            });
          }
        }
      } else {
        data.twoLvCheck = false;
        for (let i = 4; i <= 9; i++) {
          if (study.subjectStatusEntry[i].status == "unavailable") {
            study.subjectStatusEntry[i].status = "can-do";
            update_array.push({
              study_id: study.study_id,
              subject_id: study.subjectStatusEntry[i].subject_id,
              status: "can-do",
              grade: 0,
            });
          }
        }
      }
    }
    return update_array;
  }
  async function checkPrivatrecht(study) {
    const update_array = [];
    const excludesIds = ["18", "19"];
    const glzr = study.subjectStatusEntry.find(
      (i) => i.subject_id == "13"
    );
    if (glzr.status == "done") {
      study.subjectStatusEntry.forEach((item) => {
        if (
          item.subject_id >= 15 &&
          !excludesIds.includes(item.subject_id) &&
          item.subject_id <= 21 &&
          item.status == "unavailable"
        ) {
          item.status = "can-do";
          update_array.push({
            study_id: study.study_id,
            subject_id: item.subject_id,
            status: item.status,
            grade: 0,
          });
        }
      });
    } else if (glzr.status !== "done") {
      study.subjectStatusEntry.forEach((item) => {
        if (item.subject_id >= 15 && item.subject_id <= 21 && !excludesIds.includes(item.subject_id)) {
          item.status = "unavailable";
          update_array.push({
            study_id: study.study_id,
            subject_id: item.subject_id,
            status: item.status,
            grade: 0,
          });
        }
      });
    }
    return update_array;
  }
 async function checkÖffRecht(study) {
  const update_array = [];
    const excludesIds = ["27", "28"];
    const glzr = study.subjectStatusEntry.find(
      (i) => i.subject_id == "12"
    );
    if (glzr.status == "done") {
      study.subjectStatusEntry.forEach((item) => {
        if (
          item.subject_id >= 24 &&
          !excludesIds.includes(item.subject_id) &&
          item.subject_id <= 33 &&
          item.status == "unavailable"
        ) {
          item.status = "can-do";
          update_array.push({
            study_id: study.study_id,
            subject_id: item.subject_id,
            status: item.status,
            grade: 0,
          });
        }
      });
    } else if (glzr.status !== "done") {
      study.subjectStatusEntry.forEach((item) => {
        if (item.subject_id >= 24 && item.subject_id <= 33 && !excludesIds.includes(item.subject_id)) {
          item.status = "unavailable";
          update_array.push({
            study_id: study.study_id,
            subject_id: item.subject_id,
            status: item.status,
            grade: 0,
          });
        }
      });
    }
    return update_array;
  }
 async function checkPrRechtSchr(study) {
  const update_array = [];
    const zff = study.subjectStatusEntry.find(
      (i) => i.subject_id == "17"
    );
    const fp = study.subjectStatusEntry.find(
      (i) => i.subject_id == "18"
    );
    if (zff.status == "done" && fp.status == "unavailable") {
      fp.status = "can-do";
      update_array.push({
        study_id: study.study_id,
        subject_id: fp.subject_id,
        status: fp.status,
        grade: 0,
      });
    } else if (zff.status !== "done") {
      fp.status = "unavailable";
      update_array.push({
        study_id: study.study_id,
        subject_id: fp.subject_id,
        status: fp.status,
        grade: 0,
      });
    }
    return update_array;
  }
 async function checkPrRechtMue(study) {
  const update_array = [];
    const fpSchr = study.subjectStatusEntry.find(
      (i) => i.subject_id == "18"
    );
    const fpMue = study.subjectStatusEntry.find(
      (i) => i.subject_id == "19"
    );
    if (fpSchr.status == "done" && fpMue.status == "unavailable") {
      fpMue.status = "can-do";
      update_array.push({
        study_id: study.study_id,
        subject_id: fpMue.subject_id,
        status: fpMue.status,
        grade: 0,
      });
    } else if (fpSchr.status !== "done") {
      fpMue.status = "unavailable";
      update_array.push({
        study_id: study.study_id,
        subject_id: fpMue.subject_id,
        status: fpMue.status,
        grade: 0,
      });
    }
    return update_array;
  }
 async function checkOeffRechtSchr(study) {
  const update_array = [];
    const verfUndVerw = study.subjectStatusEntry.find(
      (i) => i.subject_id == "24"
    );
    const verwUndRecht = study.subjectStatusEntry.find(
      (i) => i.subject_id == "25"
    );
    const öffWire = study.subjectStatusEntry.find(
      (i) => i.subject_id == "26"
    );
    const fp = study.subjectStatusEntry.find(
      (i) => i.subject_id == "27"
    );
    if (
      [verfUndVerw, verwUndRecht, öffWire].every(
        (item) => item.status == "done"
      ) &&
      fp.status == "unavailable"
    ) {
      fp.status = "can-do";
      update_array.push({
        study_id: study.study_id,
        subject_id: fp.subject_id,
        status: fp.status,
        grade: 0,
      });
    } else if (
      [verfUndVerw, verwUndRecht, öffWire].some(
        (item) => item.status !== "done"
      )
    ) {
      fp.status = "unavailable";
      update_array.push({
        study_id: study.study_id,
        subject_id: fp.subject_id,
        status: fp.status,
        grade: 0,
      });
    }
    return update_array;
  }
async function checkOeffRechtMue(study) {
  const update_array = [];
    const fpSchr = study.subjectStatusEntry.find(
      (i) => i.subject_id == "27"
    );
    const fpMue = study.subjectStatusEntry.find(
      (i) => i.subject_id == "28"
    );
    if (fpSchr.status == "done" && fpMue.status == "unavailable") {
      fpMue.status = "can-do";
      update_array.push({
        study_id: study.study_id,
        subject_id: fpMue.subject_id,
        status: fpMue.status,
        grade: 0,
      });
    } else if (fpSchr.status !== "done") {
      fpMue.status = "unavailable";
      update_array.push({
        study_id: study.study_id,
        subject_id: fpMue.subject_id,
        status: fpMue.status,
        grade: 0,
      });
    }
    return update_array;
  }
 async function checkSbwl(study, twoLvCheck) {
  const update_array = [];
    const statistik = study.subjectStatusEntry.find(
      (i) => i.subject_id == "14"
    );
    const amc = study.subjectStatusEntry.find(
      (i) => i.subject_id == "4"
    );
    const sbwl = study.subjectStatusEntry.find(
      (i) => i.subject_id == "34"
    );
    if (
      [amc, statistik].every((item) => item.status == "done") &&
      twoLvCheck == true &&
      sbwl.status == "unavailable"
    ) {
      sbwl.status = "can-do";
      update_array.push({
        study_id: study.study_id,
        subject_id: sbwl.subject_id,
        status: sbwl.status,
        grade: 0,
      });
    } else if (
      [amc, statistik].some((item) => item.status !== "done") || twoLvCheck == false
    ) {
      sbwl.status = "unavailable";
      update_array.push({
        study_id: study.study_id,
        subject_id: sbwl.subject_id,
        status: sbwl.status,
        grade: 0,
      });
    } 
    return update_array;
  }
async function checkGwaUndWahlfach(study) {
  const update_array = [];
    const glÖffRecht = study.subjectStatusEntry.find(
      (i) => i.subject_id == "12"
    );
    const glZviliRecht = study.subjectStatusEntry.find(
      (i) => i.subject_id == "13"
    );
    const gwa = study.subjectStatusEntry.find(
      (i) => i.subject_id == "22"
    );
    const wahlfach = study.subjectStatusEntry.find(
      (i) => i.subject_id == "23"
    );
    if (
      [glÖffRecht, glZviliRecht].every(
        (item) => item.status == "done"
      ) &&
      gwa.status == "unavailable" &&
      wahlfach.status == "unavailable"

    ) {
      gwa.status = "can-do";
      wahlfach.status = "can-do";
      update_array.push({
        study_id: study.study_id,
        subject_id: gwa.subject_id,
        status: gwa.status,
        grade: 0,
      },
      {
        study_id: study.study_id,
        subject_id: wahlfach.subject_id,
        status: wahlfach.status,
        grade: 0,
      });
    } else if (
      [glÖffRecht, glZviliRecht].some(
        (item) => item.status !== "done"
      )
    ) {
      gwa.status = "unavailable";
      wahlfach.status = "unavailable";
      update_array.push({
        study_id: study.study_id,
        subject_id: gwa.subject_id,
        status: gwa.status,
        grade: 0,
      },
      {
        study_id: study.study_id,
        subject_id: wahlfach.subject_id,
        status: wahlfach.status,
        grade: 0,
      });
    }
    return update_array;
  }
  async function checkBachelor(study, twoLvCheck) {
    const update_array = [];
    const cbkFiltered = study.subjectStatusEntry.filter((item) =>
      ["4", "11", "12", "13", "14"].includes(item.subject_id)
    ); 
    const gwa = study.subjectStatusEntry.find(
      (i) => i.subject_id == 22
    );
    const bachelor = study.subjectStatusEntry.find(
      (i) => i.subject_id == 35
    );
    if (
      cbkFiltered.every((item) => item.status == "done") &&
      twoLvCheck == true &&
      gwa.status == "done" &&
      bachelor.status == "unavailable"
    ) {
      bachelor.status = "can-do";
      update_array.push({
        study_id: study.study_id,
        subject_id: bachelor.subject_id,
        status: bachelor.status,
        grade: 0,
      });
    } else if (
      cbkFiltered.some((item) => item.status !== "done") ||
      gwa.status !== "done" ||
      twoLvCheck == false
    ) {
      bachelor.status = "unavailable";
      update_array.push({
        study_id: study.study_id,
        subject_id: bachelor.subject_id,
        status: bachelor.status,
        grade: 0,
      });
    }
    return update_array;
  }


  export default {
    /** @TIKO @TODO Documentation
  * Checks the study plan for the WISO IBW Bachelor
  * @param {*} study The study object
  * @param {*} data 
  * @param {*} isRunningCheckCBK 
  * @param {*} twoLvCheck 
  * @param {*} ectsCount 
  * @returns {Array} update_array An array of subject updates that have to be pushed to the backend
  */
 async executeAll(study, data, isRunningCheckCBK, twoLvCheck) {
   const update_array = [
     /**
      * { 
      *   study_id: string,
      *   subject_id: number,
      *   status: string,
      *   grade: number
      * }
      * */
   ];
   const cbkValues = await checkCBK(study, isRunningCheckCBK, data);
    const lvChoiceValues = await checkLvChoice(study, isRunningCheckCBK, twoLvCheck, data);
    const privatrechtValues = await checkPrivatrecht(study);
    const öffRechtValues = await checkÖffRecht(study);
    const prRechtSchrValues = await checkPrRechtSchr(study);
    const prRechtMueValues = await checkPrRechtMue(study);
    const oeffRechtSchrValues = await checkOeffRechtSchr(study);
    const oeffRechtMueValues = await checkOeffRechtMue(study);
    const sbwlValues = await checkSbwl(study, twoLvCheck);
    const gwaUndWahlfachValues = await checkGwaUndWahlfach(study);
    const bachelorValues = await checkBachelor(study, twoLvCheck);

    update_array.push({cbkValues})
    update_array.push({lvChoiceValues})
    update_array.push({privatrechtValues})
    update_array.push({öffRechtValues})
    update_array.push({prRechtSchrValues})
    update_array.push({prRechtMueValues})
    update_array.push({oeffRechtSchrValues})
    update_array.push({oeffRechtMueValues})
    update_array.push({sbwlValues})
    update_array.push({gwaUndWahlfachValues})
    update_array.push({bachelorValues})

    return update_array;

  }
}