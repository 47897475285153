function showPath(subject, status) {
    if(subject._id > 4 && subject._id < 17) {
        status[0].array.forEach(obj => {
          obj.active = true
        })
        if (subject._id == 6) {
          const amc1 = status[1].array.find(
            (s) => s._id == 5
          )
          amc1.active = true
        }
      }
      else if (subject._id >= 17) {
        status[0].array.forEach(obj => {
          obj.active = true
        })
        const glzr = status[1].array.find(
            (s) => s._id == 14
          )
          glzr.active = true
        if (subject._id == 20) {
          const zff = status[2].array.find(
            (s) => s._id == 17
          )
          zff.active = true
        }
        else if (subject._id == 21) {
          const zff = status[2].array.find(
            (s) => s._id == 17
          )
          const fpsPrivatrecht = status[2].array.find(
            (s) => s._id == 20
          )
          zff.active = true;
          fpsPrivatrecht.active = true
        }
        else if (subject._id == 25) {
          const ifs1 = status[2].array.find(
            (s) => s._id == 22
          )
          const ifs2 = status[2].array.find(
            (s) => s._id == 23
          )
          const öffRecht = status[2].array.find(
            (s) => s._id == 24
          )
          ifs1.active = true;
          ifs2.active = true;
          öffRecht.active = true;
        }
        else if (subject._id == 26) {
          const fpsÖffRecht = status[2].array.find(
            (s) => s._id == 25
          )
          const ifs1 = status[2].array.find(
            (s) => s._id == 22
          )
          const ifs2 = status[2].array.find(
            (s) => s._id == 23
          )
          const öffRecht = status[2].array.find(
            (s) => s._id == 24
          )
          fpsÖffRecht.active = true;
          ifs1.active = true;
          ifs2.active = true;
          öffRecht.active = true;
        }
        else if (subject._id == 37) {
          status[1].array.forEach(
            (sub) => {
              if (sub._id >= 5 && sub._id <= 12) {
                sub.active = true;
              }
            }
          )
          const statistik = status[1].array.find(
            (s) => s._id == 15
          )
          statistik.active = true;
        }
        else if (subject._id == 38) {
          status[1].array.forEach(
            (obj) => {
              obj.active = true;
            }
          )
          const gwa = status[2].array.find(
            (s) => s._id == 36
          )
          gwa.active = true;
        }
      }
}

  export default {
    executePath(subject, status) {
        showPath(subject, status)
    }
  }