import { useUserStore } from "@/store/user.store";
import router from "@/router";


// Replace the host variable with the protocol (http(s)) and the domain of the current backend you are running.
// This will depend on the environment you are running the backend in.
// WARNING: Don't put a '/' after the link, otherwise it will fail.

export const HOST = "https://studienplaner.oeh-wu.at";
//export const HOST = "https://jectory.org";
//export const HOST = "http://localhost:8080"

export function getOAuthUrl() {
    const root_url = `https://oauth.oeh-wu.at/authorize`;

    const options = {
        redirect_uri: `${HOST}/api/auth/oauth`,
        client_id: "profcheck-dev",
        response_type: "code",
        prompt: "consent",
        scope: "basic, study",
        state: "/",
    };

    const qs = new URLSearchParams(options);
    const url = `${root_url}?${qs}`;
    return url;
}

export function requestMiddleware(config) {
    const access_token = localStorage.getItem("access_token");
    const refresh_token = localStorage.getItem("refresh_token");

    if (access_token) {
        config.headers["Authorization"] = "Bearer " + access_token;
    }

    if (refresh_token) {
        config.headers["x-refresh"] = refresh_token;
    }

    return config;
}

export function checkResponse(res) {
    try {
        let new_at = res.response.headers["x-new-access-token"];

        if (new_at) {
            // just for development
            console.log("New access token received");

            this.localStorage.setItem("access_token", new_at);

            return res;
        }

        if (res.response.status === 401) {
            console.log("Failed to authorize - or reauthorize");
            useUserStore().clearAuthState();
            router.push("/");
        }
    } catch (e) {
        return res;
    }

    return res;
}