<template>
  <div class="row">
    <div class="col-xs-12 col-md-4 row q-pa-xl justify-center">
      <Transition name="first">
        <q-card
          v-if="!active && !splitWiso19 && !splitWiso23"
          class="my-card"
          @click="() => (active = true)"
        >
          <q-card-section>
            <h2 class="title text-center">WISO</h2>
          </q-card-section>
        </q-card>
      </Transition>
      <div
        class="column q-gutter-y-md justify-center"
        v-click-away="() => (active = false)"
      >
         <Transition name="second">
            <q-card class="my-card" v-if="active && !splitWiso19">
              <q-card-actions @click="splitWiso1">
                <h5 style="color: #5bbdf4">
                  Wirtschafts- und Sozialwissenschaften 2019
                </h5>
              </q-card-actions>
            </q-card>
         </Transition>
        <Transition name="second">
            <q-card class="my-card" v-if="active && !splitWiso23">
              <q-card-actions @click="splitWiso2">
                <h5 style="color: #5bbdf4">
                  Wirtschafts- und Sozialwissenschaften 2023
                </h5>
              </q-card-actions>
            </q-card>
        </Transition>

        <!-- Wirtschafts- und Sozialwissenschaften 2019 -->
        <div
          class="column q-gutter-sm"
          v-click-away="() => (splitWiso19 = false)"
        >       
        <q-card
              class="my-card-small row flex-center"
              v-if="active && splitWiso19"
            >
              <q-card-actions>
                <router-link
                  style="text-decoration: none; color: inherit"
                  to="/plan/wiso-bwl"
                >
                  <div class="text-body1" style="color: #5bbdf4">
                    Betriebswirtschaft
                  </div>
                </router-link>
              </q-card-actions>
            </q-card>
          <Transition name="second">
            <q-card
              class="my-card-small row flex-center"
              v-if="active && splitWiso19"
            >
              <q-card-actions>
                <router-link
                  style="text-decoration: none; color: inherit"
                  to="/plan/wiso-ibw"
                >
                  <div class="text-body1" style="color: #5bbdf4">
                    Internationale Betriebswirtschaft
                  </div>
                </router-link>
              </q-card-actions>
            </q-card>
          </Transition>
          <Transition name="second">
            <q-card
              class="my-card-small row flex-center"
              v-if="active && splitWiso19"
            >
              <q-card-actions>
                <router-link
                  style="text-decoration: none; color: inherit"
                  to="/plan/wiso-vwl"
                >
                  <div class="text-body1" style="color: #5bbdf4">
                    Volkswirtschaft
                  </div>
                </router-link>
              </q-card-actions>
            </q-card>
          </Transition>
          <Transition name="second">
            <q-card
              class="my-card-small row flex-center"
              v-if="active && splitWiso19"
            >
              <q-card-actions>
                <router-link
                  style="text-decoration: none; color: inherit"
                  to="/plan/wiso-sz"
                >
                  <div class="text-body1" style="color: #5bbdf4">
                    Sozioökonomie
                  </div>
                </router-link>
              </q-card-actions>
            </q-card>
          </Transition>
          <Transition name="second">
            <q-card
              class="my-card-small row flex-center"
              v-if="active && splitWiso19"
            >
              <q-card-actions>
                <router-link
                  style="text-decoration: none; color: inherit"
                  to="/plan/wiso-winf"
                >
                  <div class="text-body1" style="color: #5bbdf4">
                    Wirtschaftsinformatik
                  </div>
                </router-link>
              </q-card-actions>
            </q-card>
          </Transition>
        </div>
<!-- Wirtschafts- und Sozialwissenschaften 2023 -->
        <div
          class="column q-gutter-sm"
          v-click-away="() => (splitWiso23 = false)"
        >       
        <q-card
              class="my-card-small row flex-center"
              v-if="active && splitWiso23"
            >
              <q-card-actions>
                <router-link
                  style="text-decoration: none; color: inherit"
                  to="/plan/wiso-bwl-23"
                >
                  <div class="text-body1" style="color: #5bbdf4">
                    Betriebswirtschaft
                  </div>
                </router-link>
              </q-card-actions>
            </q-card>
          <Transition name="second">
            <q-card
              class="my-card-small row flex-center"
              v-if="active && splitWiso23"
            >
              <q-card-actions>
                <router-link
                  style="text-decoration: none; color: inherit"
                  to="/plan/wiso-ibw-23"
                >
                  <div class="text-body1" style="color: #5bbdf4">
                    Internationale Betriebswirtschaft
                  </div>
                </router-link>
              </q-card-actions>
            </q-card>
          </Transition>
          <Transition name="second">
            <q-card
              class="my-card-small row flex-center"
              v-if="active && splitWiso23"
            >
              <q-card-actions>
                <router-link
                  style="text-decoration: none; color: inherit"
                  to="/plan/wiso-vwl-23"
                >
                  <div class="text-body1" style="color: #5bbdf4">
                    Volkswirtschaft
                  </div>
                </router-link>
              </q-card-actions>
            </q-card>
          </Transition>
          <Transition name="second">
            <q-card
              class="my-card-small row flex-center"
              v-if="active && splitWiso23"
            >
              <q-card-actions>
                <router-link
                  style="text-decoration: none; color: inherit"
                  to="/plan/wiso-winf-23"
                >
                  <div class="text-body1" style="color: #5bbdf4">
                    Wirtschaftsinformatik
                  </div>
                </router-link>
              </q-card-actions>
            </q-card>
          </Transition>
          <Transition name="second">
            <q-card
              class="my-card-small row flex-center"
              v-if="active && splitWiso23"
            >
              <q-card-actions>
                <router-link
                  style="text-decoration: none; color: inherit"
                  to="/plan/wiso-wupol-23"
                >
                  <div class="text-body1" style="color: #5bbdf4">
                    Wirtschaft - Umwelt - Politik
                  </div>
                </router-link>
              </q-card-actions>
            </q-card>
          </Transition>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-4 row q-pa-xl justify-center">
      <Transition name="first">
        <q-card
          v-if="!splitWire"
          class="my-card"
          @click="() => (splitWire = true)"
        >
          <q-card-section>
            <h2 class="title text-center">WIRE</h2>
          </q-card-section>
        </q-card>
      </Transition>
      <Transition name="second">
        <div
          class="row q-gutter-y-md justify-center"
          v-if="splitWire"
          v-click-away="() => (splitWire = false)"
        >
          <q-card class="my-card">
            <q-card-actions class="flex-center">
              <router-link
                style="text-decoration: none; color: inherit"
                to="/plan/wire"
              >
                <h5 style="color: #5bbdf4">Wirtschaftsrecht 2016</h5>
              </router-link>
            </q-card-actions>
          </q-card>
          <q-card class="my-card">
            <q-card-actions class="flex-center">
              <router-link
                style="text-decoration: none; color: inherit"
                to="/plan/wire-23"
              >
                <h5 style="color: #5bbdf4">Wirtschaftsrecht 2023</h5>
              </router-link>
            </q-card-actions>
          </q-card>
        </div>
      </Transition>
    </div>
    <div class="col-xs-12 col-md-4 row q-pa-xl justify-center">
      <q-card class="my-card">
        <q-card-section>
          <router-link
            style="text-decoration: none; color: inherit"
            to="/plan/bbe"
          >
            <h2 class="title text-center">BBE</h2>
          </router-link>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      splitWiso19: false,
      splitWiso23: false,
      splitWire: false,
    };
  },
  methods: {
    splitWiso1() {
      this.splitWiso19 = true;
      this.splitWiso23 = false;
    },
    splitWiso2() {
      this.splitWiso19 = false;
      this.splitWiso23 = true;
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");

.title {
  font-family: Staatliches, sans-serif;
  color: #5bbdf4 !important;
}
.my-card {
  width: 100%;
  cursor: pointer;
  max-width: 400px;
  max-height: 180px;
}
.my-card-small {
  min-height: 80px;
  max-height: 100px;
}
.first-enter-active {
  transition: all 0.2s ease;
}
.first-leave-active {
  transition: all 0.2s ease;
}

.first-enter-from,
.first-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
.second-enter-active {
  transition: all 0.2s ease;
  transition-delay: 0.2s;
}


.second-enter-from,
.second-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>