function showPath(subject, status) {
  if (subject._id > 4 && subject._id <= 22) {
    status[0].array.forEach((obj) => {
      obj.active = true;
    });
    if (subject._id == 5 || subject._id == 6) {
      const quan2 = status[2].array.find((s) => s._id == 4);
      quan2.active = true;
    } else if (subject._id == 20) {
      status[2].array.forEach((obj) => {
        obj.active = true;
        if (obj._id == 17 || obj._id == 18 || obj._id == 21 || obj._id == 22) {
          obj.active = false;
        }
      });
    } else if (subject._id == 21) {
      const as1 = status[2].array.find((s) => s._id == 17);
      as1.active = true;
      const as2 = status[2].array.find((s) => s._id == 18);
      as2.active = true;
    }
  }
}

export default {
  executePath(subject, status) {
    showPath(subject, status);
  },
};
