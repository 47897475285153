<template>
  <div>
    <div class="section">
      <div class="title">
        <div class="div-block-1">
          <div class="div-block-1-2">
            <h1 class="heading-1">STUDIENPLANER</h1>
            <p class="paragraph-1">YOUR TOOL TO KEEP TRACK OF YOUR STUDIES</p>
          </div>
        </div>
        <div :class="titleBackground"></div>
      </div>
      <q-separator color="blue-3" inset />
      <div class="full-width info">
        <div class="row q-mb-xl row wrap justify-center">
          <div class="col-md-12">
            <div :class="bg"></div>
            <div class="text-h2 text-center q-mb-lg" id="heading-info-h5">
              <!--How we can support you-->
              {{ $t("home.first_section_heading") }}
            </div>
            <div class="text-h5 text-center" id="heading-info-h2">
              <!--Complete your studies with ease -->
             {{ $t("home.first_section_subheading") }}
            </div>
          </div>
        </div>
        <div :class="colGutter">
          <div class="col-md-4 col-xs-12" style="max-width: 600px">
            <q-card ref="card3" class="my-card full-height"  @mouseover="expandImage(3)" @mouseleave="shrinkImage(3)">
              <q-card-section class="q-pa-md">
                <q-img class="card-image" src="../assets/notebook-straight.svg" :fit="(scale - down).toFixed(0)" style="max-height: 150px"></q-img>
              </q-card-section>
              <q-card-section>
                <div class="text-h5 text-center text-weight-bold" id="heading-mycard">
                  Studienplaner
                </div>
              </q-card-section>
              <q-card-section class="q-pt-none">
                <div class="text-body1 text-start" id="text-mycard">
                  {{ $t("home.first_section_studienplaner")}}
                </div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-md-4 col-xs-12" style="max-width: 600px">
            <q-card ref="card1" class="my-card full-height"  @mouseover="expandImage(1)" @mouseleave="shrinkImage(1)">
              <q-card-section class="q-pa-md">
                <q-img class="card-image" src="../assets/lv-planer-color-2.svg" :fit="(scale - down).toFixed(0)" style="max-height: 150px"></q-img>
              </q-card-section>
              <q-card-section>
                <div class="text-h5 text-center text-weight-bold" id="heading-mycard">
                  LV-Planer
                </div>
              </q-card-section>
              <q-card-section class="q-pt-none">
                <div class="text-body1 text-start" id="text-mycard">
                  {{ $t("home.first_section_lvplaner")}}
                </div>
              </q-card-section>
            </q-card>
          </div>
          <div class="col-md-4 col-xs-12" style="max-width: 600px">
            <q-card ref="card2" class="my-card full-height" @mouseover="expandImage(2)" @mouseleave="shrinkImage(2)">
              <q-card-section class="q-pa-md">
                <q-img  class="card-image" src="../assets/profcheck-color-3.svg" :fit="(scale - down).toFixed(0)" style="max-height: 150px"></q-img>
              </q-card-section>
              <q-card-section>
                <div class="text-h5 text-center text-weight-bold" id="heading-mycard">
                  Profcheck
                </div>
              </q-card-section>
              <q-card-section class="q-pt-none">
                <div class="text-body1 text-start" id="text-mycard">
                  {{ $t("home.first_section_profcheck")}}
                </div>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </div>
      <div class="oeh-intro">
        <q-card flat>
        <div class="row">
          <div class="col-md-6 col-xs-12">
            <q-card-section class="q-mx-xl">
              <q-img src="../assets/group.svg" :fit="(scale - down).toFixed(0)" style="max-height: 550px;"></q-img>
            </q-card-section>
          </div>
          <div class="col-md-6 col-xs-12">
            <q-card-section>
              <div class="col-md-12">
            <div class="text-h2 q-pt-md q-pb-lg"><b> {{$t("home.second_section_heading")}} </b></div>
          </div>
            </q-card-section>
            <q-card-section>
              <div class="text-h5">
                {{ $t("home.second_section_text")}}
              </div>
            </q-card-section>
          </div>
        </div>
        </q-card>
      </div>
      <!-- Roadmap -->
      <div class="roadmap row justify-center">
        <div :class="roadMap" style="max-width: 80%">
          <q-timeline :layout="layout" color="amber-3">
      <q-timeline-entry heading style="font-family: Staatliches, sans-serif; color: #5bbdf4;">
        <div class="text-h3">
          <!--Our shared journey -->
          {{ $t("home.third_section_heading")}}
          </div>
      </q-timeline-entry>

      <q-timeline-entry
        :title="$t('home.third_section_roadmap_title_1')"
        subtitle="March 2022"
        side="left"
        icon="check"
        style="color: #5BA6F4"
      >
        <div style="color: grey">
          {{ $t("home.third_section_roadmap_text_1")}}
          </div>
      </q-timeline-entry>

      <q-timeline-entry
        :title="$t('home.third_section_roadmap_title_2')"
        subtitle="November 2022"
        side="right"
        icon="check"
        style="color: #5BA6F4"
      >
        <div style="color: grey">
          {{ $t("home.third_section_roadmap_text_2")}}
        </div>
      </q-timeline-entry>

      <q-timeline-entry
        :title="$t('home.third_section_roadmap_title_3')"
        subtitle="May 2023"
        side="left"
        icon="check"
        style="color: #5BA6F4"
      >
        <div style="color: grey">
          {{ $t("home.third_section_roadmap_text_3")}}
        </div>
      </q-timeline-entry>

      <q-timeline-entry
        :title="$t('home.third_section_roadmap_title_4')"
        subtitle="Oktober 2023"
        side="right"
        icon="check"
        style="color: #5BA6F4;"
      >
        <div style="color: grey">
          {{ $t("home.third_section_roadmap_text_4")}}
        </div>
      </q-timeline-entry>

      <q-timeline-entry
        :title="$t('home.third_section_roadmap_title_5')"
        subtitle="April 2024"
        side="left"
        style="color: #5BA6F4;"
      >
        <div style="color: grey">
          {{ $t("home.third_section_roadmap_text_5")}}
        </div>
      </q-timeline-entry>

      <q-timeline-entry
        :title="$t('home.third_section_roadmap_title_6')"
        subtitle="Mai 2024"
        side="right"
        style="color: #5BA6F4"
      >
        <div style="color: grey">
          {{ $t("home.third_section_roadmap_text_6")}}
        </div>
      </q-timeline-entry>

      <q-timeline-entry
        :title="$t('home.third_section_roadmap_title_7')"
        subtitle="November 2024"
        side="left"
        style="color: #5BA6F4"
      >
        <div style="color: grey">
          {{ $t("home.third_section_roadmap_text_7")}}
        </div>
      </q-timeline-entry>
    </q-timeline>
        </div>
      </div>
      <div :class="bg2"></div>
      <div class="select">
        <div class="row q-mb-xl row wrap justify-center">
          <div class="col-md-12">
            <div class="text-h2 text-center q-mb-lg"><!--Select your Study --> {{$t("home.fourth_section_heading")}} </div>
            <div class="text-h5 text-center" id="heading-info-h2">
              <!--Choose your study and add it to your planner --> {{$t("home.fourth_section_subheading")}}
            </div>
          </div>
        </div>
        <StudyNav />
      </div>
    </div>
  </div>
</template>

<script>

import StudyNav from "../components/StudyNav.vue";


export default {
  components: {
    StudyNav,
  },
  data: () => {
    return {
      scale: 1,
      down: 0
    }
  },
  mounted() {
  },
  computed: {
    bg() {
      return this.$q.platform.is.desktop ? "bg" : " ";
    },
    bg2() {
      return this.$q.platform.is.desktop ? "bg-2" : " ";
    },
    titleBackground() {
      return this.$q.platform.is.desktop ? "title-background" : "title-background-mobile";
    },
    layout () {
       return this.$q.screen.lt.sm ? 'dense' : (this.$q.screen.lt.md ? 'comfortable' : 'loose')
    },
    colGutter () {
      return this.$q.platform.is.desktop ? 'full-width row wrap justify-center q-mt-xl q-col-gutter-md' : 'full-width row wrap justify-center q-mt-xl q-col-gutter-y-md'
    },
    roadMap () {
      return this.$q.platform.is.desktop ? 'row q-px-xl q-mx-xl' : 'row q-px-sm q-mx-sm'
    }
  },
  methods: {
    expandImage(cardId) {
      if(this.$q.platform.is.desktop) {
      let img = (this.$refs[`card${cardId}`])
      img.$el.style.transition = 'transform 0.6s ease';
      img.$el.style.transform = 'scale(1.2)';
      img.$el.style.zIndex = '2'
      img.$el.style.backgroundColor = "white"
      } 
    },
    shrinkImage(cardId) {
      if(this.$q.platform.is.desktop) {
      let img = (this.$refs[`card${cardId}`])
      img.$el.style.transition = 'transform 0.6s ease';
      img.$el.style.transform = 'scale(1)';
      img.$el.style.zIndex = '1';
      img.$el.style.backgroundColor = "transparent"
      }
    }
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.section {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.bg {
  position: absolute;
  width: 100%;
  height: 200vh;
  background-image: url('../assets/bg.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
  opacity: 0.2;

}
.bg-2 {
  position: absolute;
  right: 0;
  width: 48%;
  height: 150vh;
  background-image: url('../assets/bg-2.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
  opacity: 0.2;
}

.title-background {
  position: absolute;
  width: 60%;
  height: 100vh;
  background-image: url('../assets/title-background.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
}

.title-background-mobile {
  position: absolute;
  width: 100%;
  height: 60vh;
  background-image: url('../assets/title-background.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
  margin-top: -20%;
}

.my-card {
  background-color: transparent;
  overflow: auto;
  transition: transform 0.6s ease;
}

.title {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.div-block-1 {
  width: 80%;
  height: 100%;
}

.div-block-1-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
}

.heading-1 {
  font-family: Staatliches, sans-serif;
  color: #5bbdf4;
  font-size: 13vw;
  font-weight: 400;
  text-align: center;
}

.paragraph-1 {
  font-family: Staatliches, sans-serif;
  color: #5bbdf4;
  font-weight: 400;
  text-align: center;
  font-size: 2vw;
  margin-top: -5%;
}

.info {
  height: fit-content;
  width: 100%;
  color: #5bbdf4;
  margin-bottom: 40vh;
  margin-top: 30vh;
}

.oeh-intro {
  color: #5bbdf4;
  margin-bottom: 20vh;
}

#heading-info-h5 {
  font-family: Staatliches, sans-serif;
}

#heading-info-h2 {
  font-family: Poppins, sans-serif;
}

#heading-mycard #text-mycard {
  font-family: Poppins, sans-serif;
  color: #37aef3
}

.select {
  height: fit-content;
  width: 100%;
  font-family: Staatliches, sans-serif;
  color: #5bbdf4;
  margin-bottom: 10vh;
  margin-top: 30vh;
}

@media screen and (max-width: 321px) {
  .title {
    height: 50vh;
    margin-bottom: 10%;
  }

  .div-block-1 {
    width: 100%;
  }

  .paragraph-1 {
    font-size: 0.6rem;
    margin-top: -15%;
  }
}

@media screen and (min-width: 321px) and (max-width: 375px) {
  .title {
    height: 55vh;
  }

  .div-block-1 {
    width: 100%;
  }

  .heading-1 {
    margin-top: -25%;
    font-size: 4rem;
  }

  .paragraph-1 {
    font-size: 0.6rem;
    margin-top: -8%;
  }
}

@media screen and (min-width: 375px) and (max-width: 425px) {
  .title {
    height: 45vh;
  }

  .div-block-1 {
    width: 100%;
  }

  .heading-1 {
    margin-top: -25%;
    font-size: 4rem;
  }

  .paragraph-1 {
    font-size: 1.1rem;
    margin-top: -12%;
  }
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .title {
    height: 85vh;
  }

  .div-block-1 {
    width: 100%;
  }

  .heading-1 {
    font-size: 4.5rem;
  }

  .paragraph-1 {
    font-size: 1rem;
  }

  .info {
    height: fit-content;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .div-block-1 {
    width: 100%;
  }

  .heading-1 {
    font-size: 8rem;
  }

  .title-background-mobile {
    margin-top: 0;
  }
}</style>