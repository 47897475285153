import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import router from './router'
import { createPinia } from 'pinia'
import VueClickAway from 'vue3-click-away';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import VNetworkGraph from "v-network-graph"
import "v-network-graph/lib/style.css"

import QCalendar from '@quasar/quasar-ui-qcalendar'
import '@quasar/quasar-ui-qcalendar/dist/index.css'

import i18n from './i18n'

const app = createApp(App);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.use(i18n);
 
app.use(router)
app.use(pinia)
app.use(Quasar, quasarUserOptions)
app.use(QCalendar) // not working?
app.use(VueClickAway)
app.use(VNetworkGraph)
app.mount('#app')