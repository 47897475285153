function showPath(subject, status) {
    if(subject._id > 4 && subject._id < 21) {
        status[0].array.forEach(obj => {
          obj.active = true
        })
        if (subject._id == 6) {
          const amc1 = status[1].array.find(
            (s) => s._id == 5
          )
          amc1.active = true
        } else if (subject._id == 14) {
            const wiko1 = status[2].array.find(
                (s) => s._id == 16
              )
            const wiko2 = status[2].array.find(
                (s) => s._id == 15
              )
              const amc1 = status[1].array.find(
                (s) => s._id == 5
              )
              const amc2 = status[1].array.find(
                (s) => s._id == 6
              )
              const wpr = status[1].array.find(
                (s) => s._id == 10
              )
              wiko1.active = true
              wiko2.active = true
              amc1.active = true
              amc2.active = true
              wpr.active = true
        } else if (subject._id == 15) {
            const wiko1 = status[2].array.find(
                (s) => s._id == 16
              )
              const amc1 = status[1].array.find(
                (s) => s._id == 5
              )
              const amc2 = status[1].array.find(
                (s) => s._id == 6
              )
              const wpr = status[1].array.find(
                (s) => s._id == 10
              )
              wiko1.active = true
              amc1.active = true
              amc2.active = true
              wpr.active = true
        }
      }
      else if (subject._id >= 21) {
        status[0].array.forEach(obj => {
          obj.active = true
        })
        const amc1 = status[1].array.find(
            (s) => s._id == 5
          )
          const amc2 = status[1].array.find(
            (s) => s._id == 6
          )
          const wpr = status[1].array.find(
            (s) => s._id == 10
          )
          amc1.active = true
          amc2.active = true
          wpr.active = true

        if (subject._id == 32 || subject._id == 33 ) {
          const bis1 = status[1].array.find(
            (s) => s._id == 7
          )
          const statistik = status[1].array.find(
            (s) => s._id == 11
          )
          bis1.active = true;
          statistik.active = true
        }
        else if (subject._id == 27) {
            const wiko1 = status[2].array.find(
                (s) => s._id == 26
              )
              wiko1.active = true
        } else if (subject._id == 28 || subject._id == 29) {
            const wiko2 = status[2].array.find(
                (s) => s._id == 27
              )
              wiko2.active = true
        }
        else if (subject._id == 36) {
            const bis1 = status[1].array.find(
                (s) => s._id == 7
              )
              const statistik = status[1].array.find(
                (s) => s._id == 11
              )
              const gwa = status[2].array.find(
                (s) => s._id == 30
              )
              const forschungsmethoden = status[2].array.find(
                (s) => s._id == 31
              )
              bis1.active = true;
              statistik.active = true
              gwa.active = true;
              forschungsmethoden.active = true
        }
      }
}

  export default {
    executePath(subject, status) {
        showPath(subject, status)
    }
  }