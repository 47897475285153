import bbeChecker from "./checkers/bbe";
import wireChecker from "./checkers/wire";
import wisoBwlChecker from "./checkers/wiso-bwl";
import wisoIbwChecker from "./checkers/wiso-ibw";
import wisoSzChecker from "./checkers/wiso-sz";
import wisoVwlChecker from "./checkers/wiso-vwl";
import wisoWinfChecker from "./checkers/wiso-winf";
import wire23Checker from "./checkers/wire-23";
import wisoBwl23Checker from "./checkers/wiso-bwl-23";
import wisoIbw23Checker from "./checkers/wiso-ibw-23";
import wisoVwl23Checker from "./checkers/wiso-vwl-23";
import wisoWinf23Checker from "./checkers/wiso-winf-23";
import wisoWupol23Checker from "./checkers/wiso-wupol-23";

import bbeStudy from "./plans/bbe.json";
import WireStudy from "./plans/wire.json";
import WisoBwlStudy from "./plans/wiso-bwl.json";
import WisoIbwStudy from "./plans/wiso-ibw.json";
import WisoSzStudy from "./plans/wiso-sz.json";
import WisoVwlStudy from "./plans/wiso-vwl.json";
import WisoWinfStudy from "./plans/wiso-winf.json";
import Wire23Study from "./plans/wire-23.json";
import WisoBwl23Study from "./plans/wiso-bwl-23.json";
import WisoIbw23Study from "./plans/wiso-ibw-23.json";
import WisoVwl23Study from "./plans/wiso-vwl-23.json";
import WisoWinf23Study from "./plans/wiso-winf-23.json";
import WisoWupol23Study from "./plans/wiso-wupol-23.json";

import wirePath from "./paths/wire";
import wire23Path from "./paths/wire-23";
import bbePath from "./paths/bbe";
import wisoBwlPath from "./paths/wiso-bwl";
import wisoIbwPath from "./paths/wiso-ibw";
import wisoVwlPath from "./paths/wiso-vwl";
import wisoSzPath from "./paths/wiso-sz";
import wisoWinfPath from "./paths/wiso-winf";
import wisoBwl23Path from "./paths/wiso-bwl-23";
import wisoIbw23Path from "./paths/wiso-ibw-23";
import wisoWinf23Path from "./paths/wiso-winf-23";
import wisoVwl23Path from "./paths/wiso-vwl-23";
import wisoWupol23Path from "./paths/wiso-wupol-23";


/**
@TODO Uncomment this when upgrading to vue3 and typescript

export const enum STUDY_ID {
    BBE = "bbe",
    WIRE = "wire",
    WISO_BWL = "wiso-bwl",
    WISO_IBW = "wiso-ibw",
    WISO_VWL = "wiso-vwl",
    WISO_SZ = "wiso-sz",
    WISO_WINF = "wiso-winf"
}
*/

export function getChecker(study_id) {
  switch (study_id) {
    case "bbe":
      return bbeChecker;
    case "wire":
      return wireChecker;
    case "wiso-bwl":
      return wisoBwlChecker;
    case "wiso-ibw":
      return wisoIbwChecker;
    case "wiso-sz":
      return wisoSzChecker;
    case "wiso-vwl":
      return wisoVwlChecker;
    case "wiso-winf":
      return wisoWinfChecker;
    case "wire-23":
      return wire23Checker;
    case "wiso-bwl-23":
      return wisoBwl23Checker;
    case "wiso-ibw-23":
      return wisoIbw23Checker;
    case "wiso-vwl-23":
      return wisoVwl23Checker;
    case "wiso-winf-23":
      return wisoWinf23Checker;
    case "wiso-wupol-23":
      return wisoWupol23Checker;
  }
}

export function getStudyObject(study_id) {
  switch (study_id) {
    case "bbe":
      return bbeStudy;
    case "wire":
      return WireStudy;
    case "wiso-bwl":
      return WisoBwlStudy;
    case "wiso-ibw":
      return WisoIbwStudy;
    case "wiso-sz":
      return WisoSzStudy;
    case "wiso-vwl":
      return WisoVwlStudy;
    case "wiso-winf":
      return WisoWinfStudy;
    case "wire-23":
      return Wire23Study;
    case "wiso-bwl-23":
      return WisoBwl23Study;
    case "wiso-ibw-23":
      return WisoIbw23Study;
    case "wiso-vwl-23":
      return WisoVwl23Study;
    case "wiso-winf-23":
      return WisoWinf23Study;
    case "wiso-wupol-23":
      return WisoWupol23Study;
  }
}

export function getStudyPath(study_id) {
  switch (study_id) {
    case "wire":
      return wirePath;
    case "wiso-bwl":
      return wisoBwlPath;
    case "wiso-ibw":
      return wisoIbwPath;
    case "wiso-vwl":
      return wisoVwlPath;
    case "wiso-sz":
      return wisoSzPath;
    case "wiso-winf":
      return wisoWinfPath;
    case "bbe":
      return bbePath;
    case "wire-23":
      return wire23Path;
    case "wiso-bwl-23":
      return wisoBwl23Path;
    case "wiso-ibw-23":
      return wisoIbw23Path;
    case "wiso-winf-23":
      return wisoWinf23Path;
    case "wiso-vwl-23":
      return wisoVwl23Path;
    case "wiso-wupol-23":
      return wisoWupol23Path;
  }
}
