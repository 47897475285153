import { defineStore } from "pinia";
import axios from "axios";
import { ref } from "vue";
import { getStudyObject } from "./study_logic";

import { HOST, requestMiddleware, checkResponse } from "./config";

const planner_instance = axios.create({
  baseURL: `${HOST}/api/planner/v2`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  timeout: 10000,
});

planner_instance.interceptors.request.use(requestMiddleware, (error) => {
  Promise.reject(error);
});

planner_instance.interceptors.response.use((res) => res, checkResponse);

const auth_instance = axios.create({
  baseURL: `${HOST}/api/auth`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  timeout: 10000,
});

auth_instance.interceptors.request.use(requestMiddleware, (error) => {
  Promise.reject(error);
});

auth_instance.interceptors.response.use(checkResponse);

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      loggedIn: ref(false),
      debug: true,
      user: {
        /**
         * _id: string,
         * student_id: string,
         * role: "USER", "ADMIN", "MODERATOR"
         * studies: [
         *    {
         *      study_id: string; // The study id
         *      subject_states: [{
         *        subject_id: string;
         *        status: string;
         *        grade: number;
         *      }]
         *    }
         * ]
         */
      },
    };
  },
  getters: {
    studies: (state) => {
      if (state.user && state.user.studies) {
        return state.user.studies;
      }

      return [];
    },
    getShowAddStudyButton: (state) => (study_id) => {
      if (!state.loggedIn) return false;

      if (state.user && state.user.studies && state.user.studies.length > 0) {
        return !state.user.studies.some((study) => study.study_id === study_id);
      } else {
        return true;
      }
    },
    getStudentId: (state) => {
      return state.loggedIn && state.user && state.user.student_id
        ? state.user.student_id
        : "";
    },
  },
  actions: {
    /**
     * @TODO Document This
     */
    async logout(notif) {
      try {
        await auth_instance.delete("/sessions");

        notif({
          color: "positive",
          textColor: "white",
          message: "Logout successful",
          timeout: 2000,
          spinner: false,
          icon: "check",
        });

        this.clearAuthState();

        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      } catch (e) {
        console.error(e.response);

        if (e.response.status === 401) {
          this.clearAuthState(this.$axios);

          notif({
            color: "negative",
            textColor: "white",
            message: "Logout failed: Already logged out",
            timeout: 2000,
            spinner: false,
            icon: "warning",
          });
          return;
        }

        notif({
          color: "negative",
          textColor: "white",
          message: "Logout failed",
          spinner: false,
          icon: "error",
          timeout: 2000,
        });
      }
    },

    /**
     * @TODO Document This
     */
    async fetchUser() {
      try {
        const user_res = await auth_instance.get("/user");
        const res = await planner_instance.get("/user/studies");

        let at1 = user_res.headers["x-access-token"];
        let at2 = res.headers["x-new-access-token"];

        if (at1) {
          localStorage.setItem("access_token", at1);
        }

        if (at2) {
          localStorage.setItem("access_token", at2);
        }

        this.user = {
          ...user_res.data,
        };

        this.user.studies = res.data;
      } catch (e) {
        console.log(e);
      }
    },

    clearAuthState() {
      this.user = {};
      this.loggedIn = false;

      // remove the post subscription if it exists
      if (this.unsubscribe) {
        this.unsubscribe();
      }

      // remove tokens from local store
      localStorage.setItem("access_token", null);
      localStorage.setItem("refresh_token", null);

      // remove expires_in from local storage
      localStorage.removeItem("expires_in");
    },

    async login() {
      this.loggedIn = true;

      try {
        const user_res = await auth_instance.get("/user");
        const res = await planner_instance.get("/user/studies");

        this.user = {
          ...user_res.data,
        };

        this.user.studies = res.data;
      } catch (e) {
        console.log(e);
      }
    },

    async addStudy(study_info, notify) {
      if (!notify) return;

      try {
        const res = await planner_instance.post(
          `/user/studies/${study_info.study._id}`,
          JSON.stringify(study_info.subjectStatusEntry)
        );

        if (res.status === 200) {
          notify({
            title: "Study added.",
            message: "The study was added successfully.",
            type: "success",
            duration: 5000,
          });

          // response is not the study object but an OK message @TODO: fix api returning study object?
          // this.user.studies.push(res.data);
        } else {
          notify({
            title: "Error adding study",
            message:
              "The study could not be added: " + res.response.data.message,
            type: "error",
            duration: 5000,
          });
        }
      } catch (e) {
        notify({
          title: "Error adding study",
          message: "The study could not be added: " + e.response.data.message,
          type: "error",
          duration: 5000,
        });

        console.log(e);
      }
    },

    async updateStudyData() {
      try {
        const res = await planner_instance.get("/user/studies");
        this.user.studies = res.data;
      } catch (e) {
        console.log(e);
      }
    },

    /**
     * This function updates a subject within a study with the given parameters
     * @param {string} study_id
     * @param {string} subject_id
     * @param {string} status
     * @param {number} grade
     * @param {Notify} notify A notify object from the quasar framework
     * @param {boolean} repull If true, the study data will be repulled from the server
     * @returns returns nothing
     *
     * @throws {Error} Throws an error if the study could not be updated
     * @throws {Error} Throws an error if the user is not registered for that study
     * @throws {Error} Throws an error if the user is not logged in
     */
    async updateStudy(
      study_id,
      subject_id,
      status,
      grade,
      notify,
      repull = true
    ) {
      if (!notify) return;

      try {
        await planner_instance.patch(`/user/studies/${study_id}/subjects`, {
          subject_id,
          status,
          grade,
        });

        notify({
          title: "Study updated.",
          message: "Please wait...",
          //type: "success",
          spinner: true,
          badgeStyle: "display: none;",
          timeout: 100,
        });

        if (repull) {
          // Nur repull, wenn gewünscht
          await this.updateStudyData();
        }
        return this.user.studies;
      } catch (e) {
        notify({
          title: "Error updating study",
          message: "The study could not be updated: " + e.response.data.message,
          type: "error",
          duration: 5000,
        });

        console.log(e);
      }
    },

    async setBulkSubject(study_id, states, notify) {
      if (!notify) return;

      try {
        await planner_instance.patch(`/user/studies/${study_id}/bulk`, {
          states: states,
        });

        notify({
          title: "Study updated.",
          message: "Please wait...",
          //type: "success",
          spinner: true,
          badgeStyle: "display: none;",
          timeout: 100,
        });

        await this.updateStudyData();
        return this.user.studies;
      } catch (e) {
        notify({
          title: "Error updating study",
          message: "The study could not be updated: " + e.response.data.message,
          type: "error",
          duration: 5000,
        });

        console.log(e);
      }
    },

    /**
     * This function returns a study object from the user if the user is registered for that study
     * @param {string} study_id A study id
     * @returns study object, if the user is not registered for that study it returns false
     */
    getStudy(study_id) {
      // return study data from store
      let study =
        this.user.studies.length > 0
          ? this.user.studies.filter((s) => s.study_id === study_id)
          : [];

      if (study.length > 0) {
        return study[0];
      } else {
        return false;
      }
    },
    async getSubjectEntries(study_id) {
      try {
        const data = await this.getStudy(study_id);
        return data.subject_states;
      } catch (e) {
        console.log("user not registered for study", e);

        return false;
      }
    },

    async removeStudy(study_id, notify) {
      if (!notify) return;

      try {
        await planner_instance.delete(`/user/studies/${study_id}`);

        notify({
          title: "Study removed.",
          message: "The study was removed successfully.",
          type: "success",
          duration: 5000,
        });

        // remove study from user study array to keep consistency
        this.user.studies = this.user.studies.filter(
          (study) => study.study_id !== study_id
        );
      } catch (e) {
        notify({
          title: "Error removing study",
          message: "The study could not be removed: " + e.response.data.message,
          type: "error",
          duration: 5000,
        });

        console.log(e);
      }
    },
    async getSbwls(study_id) {
      try {
        const data = await this.getStudy(study_id);
        return data.sbwl_states;
      } catch (e) {
        console.log(e);

        return false;
      }
    },
    async addSbwl(study_id, sbwl_name, notify, sbwl) {
      console.log(sbwl);
      if (!notify) return;

      let statesArray;
      if (
        sbwl_name === "Ethics for Management, Organizations, and Society" ||
        sbwl_name == "Sustainability Reporting"
      ) {
        statesArray = [
          { grade: 0, status: "can-do", ects: 6 },
          { grade: 0, status: "can-do", ects: 6 },
          { grade: 0, status: "can-do", ects: 4 },
          { grade: 0, status: "can-do", ects: 4 },
        ];
      } else if (sbwl_name === "Economics Fields") {
        statesArray = [
          { grade: 0, status: "can-do", ects: 8 },
          { grade: 0, status: "can-do", ects: 8 },
          { grade: 0, status: "can-do", ects: 4 },
        ];
      } else if (sbwl_name === "Urban and Regional Economics") {
        statesArray = [
          { grade: 0, status: "can-do", ects: 4 },
          { grade: 0, status: "can-do", ects: 8 },
          { grade: 0, status: "can-do", ects: 4 },
          { grade: 0, status: "can-do", ects: 4 },
        ];
      } else if (sbwl_name === "Business Mathematics") {
        statesArray = [
          { grade: 0, status: "can-do", ects: 8 },
          { grade: 0, status: "can-do", ects: 4 },
          { grade: 0, status: "can-do", ects: 4 },
          { grade: 0, status: "can-do", ects: 4 },
        ];
      } else if (
        sbwl_name ===
        "Ecological Economics - Economy, Climate Change and Sustainability"
      ) {
        statesArray = [
          { grade: 0, status: "can-do", ects: 5 },
          { grade: 0, status: "can-do", ects: 5 },
          { grade: 0, status: "can-do", ects: 5 },
          { grade: 0, status: "can-do", ects: 5 },
        ];
      } else if (sbwl_name === "Economics Fields") {
        statesArray = [
          { grade: 0, status: "can-do", ects: 8 },
          { grade: 0, status: "can-do", ects: 8 },
          { grade: 0, status: "can-do", ects: 4 },
        ];
      } else if (
        sbwl_name === "European and International Economic Law" ||
        sbwl_name == "Mathematical Methods" ||
        sbwl_name == "Philosophy: Logic and Ethics"
      ) {
        statesArray = [
          { grade: 0, status: "can-do", ects: 5 },
          { grade: 0, status: "can-do", ects: 5 },
        ];
      } else if (sbwl_name === "Topics in Economic and Social History") {
        statesArray = [{ grade: 0, status: "can-do", ects: 10 }];
      } else if (sbwl_name === "Courses Abroad") {
        statesArray = [...sbwl.states];
      } else {
        statesArray = [
          { grade: 0, status: "can-do", ects: 4 },
          { grade: 0, status: "can-do", ects: 4 },
          { grade: 0, status: "can-do", ects: 4 },
          { grade: 0, status: "can-do", ects: 4 },
          { grade: 0, status: "can-do", ects: 4 },
        ];
      }

      try {
        const res = await planner_instance.post(
          `/user/studies/${study_id}/sbwls/${sbwl_name}`,
          { states: statesArray }
        );
        console.log(res);
        if (res.status === 200) {
          notify({
            title: "SBWL added.",
            message: "The SBWL was added successfully.",
            type: "success",
            duration: 5000,
          });
        } else {
          notify({
            title: "Error adding SBWL",
            message:
              "The SBWL could not be added: " + res.response.data.message,
            type: "error",
            duration: 5000,
          });
        }
      } catch (e) {
        notify({
          title: "Error adding SBWL",
          message: "The SBWL could not be added: " + e.response.data.message,
          type: "error",
          duration: 5000,
        });

        console.log(e);
      }
    },

    /**
     * Sets the grade for a sbwl
     * @param {*} study_id The study id
     * @param {*} sbwl_id The name of the sbwl
     * @param {Array<Number>} grades An array of each grade for each respective sbwl iteration
     * @param {*} notify
     * @returns
     */
    async setSbwlGrade(study_id, states, sbwl_name, notify) {
      if (!notify) return;

      try {
        const res = await planner_instance.post(
          `/user/studies/${study_id}/sbwls/${sbwl_name}`,
          {
            states: states,
          }
        );

        if (res.status === 200) {
          notify({
            title: "SBWL grade set.",
            message: "The SBWL grade was set successfully.",
            type: "success",
            duration: 5000,
          });
        } else {
          notify({
            title: "Error setting SBWL grade",
            message:
              "The SBWL grade could not be set: " + res.response.data.message,
            type: "error",
            duration: 5000,
          });
        }
      } catch (e) {
        notify({
          title: "Error setting SBWL grade",
          message:
            "The SBWL grade could not be set: " + e.response.data.message,
          type: "error",
          duration: 5000,
        });

        console.log(e);
      }
    },

    async removeSbwl(study_id, sbwl_name, notify) {
      if (!notify) return;

      try {
        const res = await planner_instance.delete(
          `/user/studies/${study_id}/sbwls/${sbwl_name}`
        );

        if (res.status === 200) {
          notify({
            title: "SBWL removed.",
            message: "The SBWL was removed successfully.",
            type: "success",
            duration: 5000,
          });
        } else {
          notify({
            title: "Error removing SBWL",
            message:
              "The SBWL could not be removed: " + res.response.data.message,
            type: "error",
            duration: 5000,
          });
        }
      } catch (e) {
        notify({
          title: "Error removing SBWL",
          message: "The SBWL could not be removed: " + e.response.data.message,
          type: "error",
          duration: 5000,
        });

        console.log(e);
      }
    },
    async getFreeElectives(study_id) {
      try {
        const data = await this.getStudy(study_id);
        return data.free_electives;
      } catch (e) {
        console.log(e);

        return false;
      }
    },

    async addFreeElective(study_id, freeElective, notify) {
      if (!notify) return;

      try {
        const res = await planner_instance.post(
          `/user/studies/${study_id}/free-electives`,
          freeElective
        );
        if (res.status === 200) {
          notify({
            title: "Free Elective added.",
            message: "The Free Elective was added successfully.",
            type: "success",
            duration: 5000,
          });
        } else {
          notify({
            title: "Error adding Free Elective",
            message: "The Free Elective could not be added: " + res,
            type: "error",
            duration: 5000,
          });
        }
      } catch (e) {
        console.log(e);
        notify({
          title: "Error adding Free Elective",
          message: "The Free Elective could not be added: " + e,
          type: "error",
          duration: 5000,
        });

        console.log(e);
      }
    },
    async removeFreeElective(study_id, freeElective, notify) {
      if (!notify) return;

      try {
        const res = await planner_instance.delete(
          `/user/studies/${study_id}/free-electives/${freeElective.name}`
        );

        if (res.status === 200) {
          notify({
            title: "Free Elective removed.",
            message: "The Free Elective was removed successfully.",
            type: "success",
            duration: 5000,
          });
        } else {
          notify({
            title: "Error removing Free Elective",
            message:
              "The Free Elective could not be removed: " +
              res.response.data.message,
            type: "error",
            duration: 5000,
          });
        }
      } catch (e) {
        notify({
          title: "Error removing Free Elective",
          message:
            "The Free Elective could not be removed: " +
            e.response.data.message,
          type: "error",
          duration: 5000,
        });

        console.log(e);
      }
    },

    /**
     *
     */

    /**
     * Given a study ID, this function returns the data relevant to the dashboard.
     *
     * @param {string} study_id The study ID
     *
     * @returns {Object} The dashboard data including:
     *  - semester_ects ->
     *  - current_subjects
     *  - gpa
     *  - min_ects
     *  - max_ects
     *  - current_ects
     *  - completed_subjects
     */
    calcDashboardData(study_id) {
      const study = this.getStudy(study_id);

      if (!study)
        return {
          semester_ects: 0,
          current_subjects: 0,
          gpa: 0,
          min_ects: 0,
          max_ects: 0,
          current_ects: 0,
          completed_subjects: 0,
        };

      const sst = study.subject_states;
      const subjects = getStudyObject(study_id).subjects;
      const sbwls = study.sbwl_states;
      const sbwl_done = [];
      const sbwl_doing = [];
      const free_electives = study.free_electives;
      sbwls.forEach((obj) => {
        obj.states.forEach((stateObj) => {
          if(stateObj.status == 'done') {
            sbwl_done.push(stateObj)
          }
        })
      })
      sbwls.forEach((obj) => {
        obj.states.forEach((stateObj) => {
          if(stateObj.status == 'doing') {
            sbwl_doing.push(stateObj)
          }
        })
      })

      const doing = sst.filter((s) => s.status === "doing");
      const done = sst.filter((s) => s.status === "done");
      const done_without_sbwl = done
        .map((state) => {
          const subject = subjects.find((s) => s._id === state.subject_id);
          if (
            subject &&
            subject.subject_type !== "SBWL" &&
            subject.subject_type !== "ANY"
          ) {
            return { ...state, ects: subject.ects };
          }
          return null;
        })
        .filter((item) => item !== null);
      const combinedDone = [
        ...done_without_sbwl,
        ...sbwl_done,
        ...free_electives,
      ];
      const combinedDoing = [...doing, ...sbwl_doing];

      /**
       * Check ob Noten überall eingetragen sind
       */
      const free_electives_adjusted = free_electives.filter(
        (s) => s.grade !== null && s.grade !== 0
      );
      const combinedDone_adjusted = [
        ...done_without_sbwl,
        ...sbwl_done,
        ...free_electives_adjusted,
      ];
      const checkGradeInsert = combinedDone_adjusted.some(
        (obj) => obj.grade == 0 && obj.ects !== 2
      );

      const combinedDone_grade = combinedDone.filter((s) => s.grade !== null);

      const gpa = parseFloat(
        (combinedDone.length > 0
          ? combinedDone_grade.reduce((acc, s) => acc + s.grade * s.ects, 0) /
            combinedDone_grade.reduce((acc, s) => acc + s.ects, 0)
          : 0
        ).toFixed(2)
      );
      const curr_ects = combinedDoing
        .map((st) => {
          const subject = subjects.find((e) => e._id === st.subject_id);
          if (subject) {
            return subject.ects;
          } else if (!subject && st.ects !== undefined) {
            return st.ects;
          } else {
            return 4;
          }
        })
        .reduce((acc, e) => acc + e, 0);

      const done_ects = combinedDone
        .map((st) => {
          const subject = subjects.find((e) => e._id === st.subject_id);
          if (subject) {
            return subject.ects;
          } else if (!subject && st.ects !== undefined) {
            return st.ects;
          } else {
            return 4;
          }
        })
        .reduce((acc, e) => acc + e, 0);
      40;
      return {
        semester_ects: curr_ects,
        current_subjects: combinedDoing.length,
        gpa,
        min_ects: 0,
        max_ects: 180,
        current_ects: done_ects,
        completed_subjects: combinedDone.length,
        checkGradeInsert: checkGradeInsert,
      };
    },
    async sendFeedback(
      feedbackCategory,
      feedbackFeature,
      feedbackMessage,
      notify
    ) {
      try {
        if (
          feedbackMessage !== "" ||
          feedbackFeature !== "" ||
          feedbackCategory !== ""
        ) {
          const res = await planner_instance.post("/feedback/create", {
            category: feedbackCategory,
            feature: feedbackFeature,
            message: feedbackMessage,
          });
          if (notify) {
            if (res.status === 200) {
              notify({
                type: "positive",
                icon: "done",
                message: "Feedback successfully sent",
              });
            } else {
              notify({
                type: "negative",
                icon: "error",
                message: `Something went wrong`,
              });
            }
          }
        } else {
          notify({
            type: "negative",
            icon: "error",
            message: `Please fill out all fields`,
          });
        }
      } catch (error) {
        if (notify) {
          notify({
            type: "negative",
            icon: "error",
            message: "Something went wrong",
          });
        }
        console.log(error);
      }
    },
  },
  persist: true,
});
